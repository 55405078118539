import createGenerateClassName from "@mui/styles/createGenerateClassName";
import { alpha, createTheme } from "@mui/material/styles";
import hexToRGB from "utils/hexToRGB";

export const generateClassName = createGenerateClassName({
    // By enabling this option, if you have non-MUI elements (e.g. `<div />`)
    // using MUI classes (e.g. `.MuiButton`) they will lose styles.
    // Make sure to convert them to use `styled()` or `<Box />` first.
    disableGlobal: true,
    // Class names will receive this seed to avoid name collisions.
    seed: "mui-jss",
    productionPrefix: "acx-mui",
});

export const ThemeColors = {
    orange: "#F97850",
    orangeBackground: "#fef6f3",
    green: "#30DBB8",
    darkGreen: "#20b899",
    lightGreenBackground: "#E5F9F4",
    greenBackground: "#e5faf5",
    blue: "#3564D5",
    blueBackground: "#dbe1f0",
    lightBlue: "#5d83dd",
    lightBlueBackground: "#EFF6FF",
    darkblue: "#1D2960",
    red: "#F55656",
    darkRed: "#f22626",
    redBackground: "#FEEEEE",
    purple: "#5451FF",
    yellow: "#dfad00",
    darkYellow: "#ac8500",
    yellowBackground: "#FFFAEB",
    gray: "#727272",
    grayBackground: "#E7E7E7",
    neutralGray: "#F9FAFB",
    lightgray: "#F5F5F5",
    lightgrayBackground: "#F4F4F5",
    lightgrayBorder: "#E4E4E7",
    lightgrayHover: "#FAFAFA",
    blackFont: "#3F3F46",
    black: "#1F1F1F",
    blackLight: "rgb(31,31,31,.25)",
    white: "#FFFFFF",
};

function makePaletteColor(
    mainColor: string,
    lightColor?: string,
    contrastColor?: string,
) {
    return {
        main: mainColor,
        light: lightColor || hexToRGB(mainColor, 0.5),
        contrastColor: contrastColor,
    };
}

const theme = createTheme({
    palette: {
        primary: {
            light: "#EFF6FF",
            main: "#3364D6",
            dark: "#1E40AF",
            50: "#EFF6FF",
            100: "#DCF1FF",
            200: "#BFDBFE",
            300: "#6F95F4",
            500: "#3364D6",
            700: "#1E40AF",
            800: "#1D2A60",
        },
        secondary: {
            light: "#E5F9F4",
            main: "#32D1B7",
            dark: "#239C98",
            50: "#E5F9F4",
            100: "#DAF2EE",
            200: "#C0F2E9",
            300: "#8AE5D6",
            400: "#32D1B7",
            500: "#3364D6",
            600: "#30CAAF",
            800: "#239C98",
        },
        neutral: {
            light: "#F3F4F6",
            main: "#4B5563",
            dark: "#1F2937",
        },
        orange: {
            ...makePaletteColor(ThemeColors.orange),
            background: ThemeColors.orangeBackground,
        },
        green: {
            ...makePaletteColor(ThemeColors.green),
            background: ThemeColors.greenBackground,
            dark: ThemeColors.darkGreen,
        },
        blue: {
            ...makePaletteColor(ThemeColors.blue),
            background: ThemeColors.blueBackground,
            dark: ThemeColors.darkblue,
            light: ThemeColors.lightBlue,
        },
        lightBlue: {
            ...makePaletteColor(ThemeColors.lightBlue),
            background: ThemeColors.lightBlueBackground,
        },
        red: {
            ...makePaletteColor(ThemeColors.red),
            background: ThemeColors.redBackground,
            dark: ThemeColors.darkRed,
        },
        purple: makePaletteColor(ThemeColors.purple),
        yellow: {
            ...makePaletteColor(ThemeColors.yellow),
            background: ThemeColors.yellowBackground,
            dark: ThemeColors.darkYellow,
        },
        gray: {
            light: "#F9FAFB",
            main: "#6B7280",
            dark: "#111827",
            background: ThemeColors.grayBackground,
            50: "#F9FAFB",
            100: "#F3F4F6",
            200: "#E5E7EB",
            300: "#D1D5DB",
            400: "#9CA3AF",
            500: "#6B7280",
            600: "#4B5563",
            700: "#374151",
            800: "#4B5563",
            900: "#111827",
        },
        lightgray: {
            ...makePaletteColor(ThemeColors.lightgray),
            background: ThemeColors.lightgrayBackground,
        },
        blackFont: makePaletteColor(ThemeColors.blackFont),
        lightgrayBorder: makePaletteColor(ThemeColors.lightgrayBorder),
        lightgrayHover: makePaletteColor(ThemeColors.lightgrayHover),
        neutralGray: makePaletteColor(ThemeColors.neutralGray),
        black: makePaletteColor(
            ThemeColors.black,
            undefined,
            ThemeColors.blackLight,
        ),
        white: makePaletteColor(ThemeColors.white),
        error: {
            light: "#FEF2F2",
            main: "#EF4444",
            dark: "#7F1D1D",
            50: "#FEF2F2",
            100: "#FEE2E2",
            200: "#FECACA",
            300: "#FCA5A5",
            500: "#EF4444",
            700: "#B91C1C",
            900: "#7F1D1D",
        },
        success: {
            light: "#F0FDF4",
            main: "#22C55E",
            dark: "#14532D",
            50: "#F0FDF4",
            100: "#DCFCE7",
            200: "#BBF7D0",
            300: "#4ADE80",
            500: "#22C55E",
            700: "#15803D",
            900: "#14532D",
        },
        warning: {
            light: "#FEFCE8",
            main: "#F6B100",
            dark: "#713F12",
            50: "#FEFCE8",
            100: "#FEF3C7",
            200: "#FEF08A",
            300: "#F6B100",
            500: "#F97316",
            700: "#C2410C",
            900: "#713F12",
        },
        hasChanges: { main: "#FFFFE0" },
        background: {
            default: ThemeColors.lightgray,
        },
        highlight: {
            salmon: {
                light: "#FFEBE6",
                main: "#F97750",
                100: "#FFEBE6",
                400: "#F97750",
            },
            gold: {
                light: "#FFF9EF",
                main: "#FFD240",
                50: "#FFF9E5",
                400: "#FFD240",
            },
            eddy: {
                light: "#EBF6FD",
                main: "#17A4EB",
                50: "#EBF6FD",
                500: "#17A4EB",
            },
            purple: { main: "#7E22CE", 400: "#7E22CE" },
        },
    },
    spacing: 8,
    shape: {
        borderRadius: 4,
    },
    typography: {
        fontFamily: '"Inter", Roboto, Verdana, Arial, sans-serif',
        h1: {
            fontSize: 20,
            fontFamily: '"Inter Bold", Roboto, Verdana, Arial, sans-serif',
            fontWeight: "bold", // mui overrides this so we need to set the fontFamily to bold to actually get bold h1s
            color: ThemeColors.black,
        },
        h2: {
            fontSize: 16,
            fontFamily: '"Inter Bold", Roboto, Verdana, Arial, sans-serif',
            fontWeight: "bold", // mui overrides this so we need to set the fontFamily to bold to actually get bold h2s
            color: ThemeColors.black,
        },
        h3: {
            fontSize: "13px",
            opacity: 0.9,
            color: ThemeColors.black,
        },
        h5: {
            fontSize: "16px",
            fontWeight: "bold",
            color: ThemeColors.black,
        },
        h6: {
            fontSize: "12px",
            opacity: 0.5,
            color: ThemeColors.black,
        },
        subtitle1: {
            fontSize: 20,
            color: ThemeColors.black,
        },
        subtitle2: {
            fontSize: 16,
            color: ThemeColors.black,
        },
        body1: {
            color: ThemeColors.black,
            fontSize: 13,
        },
        body2: {
            color: ThemeColors.black,
            fontSize: 14,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontFamily: '"Inter", Roboto, Verdana, Arial, sans-serif',
                    fontWeight: "bold",
                    letterSpacing: 0,
                    textAlign: "center",
                    lineHeight: "20px",
                    fontSize: "13px",
                    textTransform: "none",
                    marginLeft: 0,
                    marginRight: 0,
                    height: 30,
                },
                containedPrimary: {
                    ":hover": {
                        backgroundColor: alpha("#3364D6", 0.4),
                        transition: 300,
                    },
                    ":disabled": {
                        backgroundColor: alpha("#3364D6", 0.4),
                        transition: 300,
                        color: "#FFFFFF",
                    },
                },
                containedSecondary: {
                    backgroundColor: "#FFFFFF",
                    border: `1px solid #E5E7EB`,
                    ":disabled": {
                        backgroundColor: "#FFFFFF",
                        color: alpha(ThemeColors.black, 0.4),
                    },
                    ":hover": {
                        backgroundColor: "#F3F4F6",
                    },
                },
                outlinedSecondary: {
                    color: ThemeColors.black,
                    border: `1px solid #E5E7EB`,
                    ":hover": {
                        border: `1px solid #E5E7EB`,
                        backgroundColor: alpha("#E5E7EB", 0.4),
                    },
                },
                textSecondary: {
                    color: ThemeColors.black,
                    ":hover": {
                        backgroundColor: alpha("#E5E7EB", 0.4),
                    },
                },
                containedError: {
                    ":hover": {
                        backgroundColor: alpha("#EF4444", 0.4),
                        transition: 300,
                    },
                    ":disabled": {
                        backgroundColor: alpha("#EF4444", 0.4),
                        transition: 300,
                        color: "#FFFFFF",
                    },
                },
                outlinedError: {
                    border: `#E5E7EB 1px solid`,
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 32,
                    height: 20,
                    padding: 0,
                    margin: 9,
                },
                track: {
                    borderRadius: 10,
                    opacity: 1,
                    backgroundColor: "#D4D4D8",
                },
                switchBase: {
                    top: 2,
                    left: 2,
                    position: "absolute",
                    padding: 0,
                    margin: 0,
                    "&$checked": {
                        transform: "translateX(12px)",
                    },
                },
                thumb: {
                    boxSizing: "border-box",
                    boxShadow: "none",
                    padding: 0,
                    margin: 0,
                    width: 16,
                    height: 16,
                },
                colorSecondary: {
                    "&$checked": {
                        color: ThemeColors.white,
                    },
                    "&$checked + $track": {
                        opacity: 1,
                        backgroundColor: ThemeColors.blue,
                    },
                    "&$disabled": {
                        color: ThemeColors.gray,
                    },
                    "&$disabled + $track": {
                        opacity: 1,
                        backgroundColor: "#D4D4D8",
                    },
                },
            },
        },
    },
});

export default theme;
