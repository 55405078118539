import { action, computed, makeObservable, observable, reaction } from "mobx";
import { WorkflowReviewStore } from "./WorkflowReviewStore";
import { BaseStore } from "stores/BaseStore";
import { WorkflowMetadata } from "services/EvaluationService";
import { LMType } from "models/LicensedModule";
import Evaluation from "models/Evaluation";
import { WorkflowConditionType } from "components/Admin/Organizations/OrganizationDetail/OrganizationWorkflows/Types/WorkflowModels";
import { EvalLicensedModule } from "components/Evaluation/Stores/EvalStore";
import { WorkflowStatus } from "models/Workflows/WorkflowInstance";
import { WFRInstanceLevelProps } from "../Components/WFRInstanceLevel/WFRInstanceLevel";
import {
    AptWorkflowUpdatePayload,
    EvalutationDisputeWorkflowUpdatePayload,
    ScoreEscalationWorkflowUpdatePayload,
    WorkflowUpdateInstanceLevelPayload,
} from "services/WorkflowService";
import { WorkflowInfoAssignedWorkflowInstance } from "../types";
import EvaluationModule from "models/EvaluationModule";
import RootStore from "stores/RootStore";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { QuestionDependencyQuestionAnswerCondition } from "models/Question";

export const UpdateWorkflow = "Update Workflow";

export class WorkflowStore extends BaseStore {
    static RejectResult: "reject" = "reject";
    static ApproveResult: "approve" = "approve";
    static CompletedResult: "complete" = "complete";

    readonly workflowReviewStore: WorkflowReviewStore;

    @observable private modulesNameById: Map<string, string> = new Map<
        string,
        string
    >();

    @observable.ref workflowMetadata: Record<
        string /*keyed by instanceId*/,
        WorkflowMetadata[]
    > = {};

    constructor(workflowReviewStore: WorkflowReviewStore) {
        super("Eval Workflow");
        makeObservable(this);
        this.workflowReviewStore = workflowReviewStore;

        reaction(
            (r) => this.workflowReviewStore.evalLicensedModules,
            (arg) => {
                arg?.filter(
                    (value) => value.lmType !== LMType.Workflow,
                ).forEach((value) => {
                    this.modulesNameById.set(value.id, value.name!);
                });
            },
        );
    }

    @action
    reset() {
        this.workflowReviewStore.modulesNameById.clear();
    }

    @action
    initialize(workflowMetadata: Record<string, WorkflowMetadata[]>) {
        this.workflowMetadata = workflowMetadata;
    }

    async updateAptWorkflow(payload: AptWorkflowUpdatePayload) {
        await this.setupAsyncTask(UpdateWorkflow, async () => {
            try {
                await this.workflowReviewStore.workflowService.updateAptWorkflow(
                    payload,
                );
            } catch (e) {
                RootStore()
                    .getStore(MessageStore)
                    .logError(
                        e instanceof Error
                            ? e.message
                            : "Error updating workflow",
                    );
            }
        });
    }

    async updateScoreEscalationWorkflow(
        payload: ScoreEscalationWorkflowUpdatePayload,
    ) {
        await this.setupAsyncTask(UpdateWorkflow, async () => {
            try {
                await this.workflowReviewStore.workflowService.updateScoreEscalationWorkflow(
                    payload,
                );
            } catch (e) {
                RootStore()
                    .getStore(MessageStore)
                    .logError(
                        e instanceof Error
                            ? e.message
                            : "Error updating workflow",
                    );
            }
        });
    }

    async updateEvaluationDisputeWorkflow(
        payload: EvalutationDisputeWorkflowUpdatePayload,
    ) {
        await this.setupAsyncTask(UpdateWorkflow, async () => {
            try {
                await this.workflowReviewStore.workflowService.updateEvaluationDisputeWorkflow(
                    payload,
                );
            } catch (e) {
                RootStore()
                    .getStore(MessageStore)
                    .logError(
                        e instanceof Error
                            ? e.message
                            : "Error updating workflow",
                    );
            }
        });
    }

    @action
    onCompleteDisputeLevel = async (
        instanceId: string,
        workflowModuleId: string,
        level: number,
        note: string,
        result: "approve" | "reject" | "complete" | null,
        moduleScores: {
            module: EvaluationModule;
            score: number;
        }[],
    ) => {
        const instance = this.workflowReviewStore.workflowReviewerInfo;

        let foundLevel = instance?.workflowInfo.workflowLevelInfos?.find(
            (_, idx) => idx === level,
        );
        if (instance && foundLevel) {
            let activeLevel: WorkflowUpdateInstanceLevelPayload = {
                workflowInstanceId: instance.workflowInfo.instanceId,
                result,
                note: note ?? "",
                workflowMessagingMetadataId:
                    foundLevel.workflowMessagingMetadataId,
                userId: this.workflowReviewStore.authStore._user.profile.sub,
                evaluationModuleId: foundLevel.evaluationModule?.id ?? "",
                evaluationModule: foundLevel.evaluationModule,
                conversationModule: null,
                createdBy:
                    this.workflowReviewStore.authStore._user.profile.email,
            };

            this.setupAsyncTask(UpdateWorkflow, async () => {
                try {
                    const answers = [
                        ...instance.workflowInfo.answers,
                        ...(instance.workflowInfo.workflowLevelInfos[level]
                            .answers ?? []),
                    ];

                    answers.forEach((answer) => {
                        answer.answerDisputes = undefined;
                    });

                    const payload: EvalutationDisputeWorkflowUpdatePayload = {
                        evaluationId: this.workflowReviewStore.evalId ?? "",
                        workflowInstanceId:
                            instance.workflowInfo.workflowInstanceId,
                        workflowInstanceLevel: activeLevel,
                        answers,
                        disputeEvaluationModuleScores: moduleScores.map(
                            ({ module, score }) => ({
                                disputeEvaluationModuleId: module.id,
                                disputeEvaluationModuleScore: score,
                            }),
                        ),
                    };
                    await this.updateEvaluationDisputeWorkflow(payload);

                    await this.workflowReviewStore.getAllModules();
                    if (this.workflowReviewStore.workflowId) {
                        await this.workflowReviewStore.getWorkflowsForReviewer(
                            this.workflowReviewStore.workflowId,
                        );
                    }
                } catch {
                    RootStore()
                        .getStore(MessageStore)
                        .logError("Error updating workflow");
                }
            });
        }
    };

    @action
    onCompleteLevel = async (
        instanceId: string,
        workflowModuleId: string,
        level: number,
        note: string,
        result: "approve" | "reject" | "complete" | null,
        moduleScore?: number,
    ) => {
        const instance = this.workflowReviewStore.workflowReviewerInfo;

        const updatedAnswers = instance?.workflowInfo.answers ?? [];

        // Remove tags from answers that are not active
        for (let i = 0; i < updatedAnswers.length; ++i) {
            const answer = updatedAnswers[i];
            const dependencies = answer.question.dependencies;
            if (dependencies) {
                const dependsOnList = dependencies.conditions.map((cond) => ({
                    dependsOnQuestionId: (
                        cond as QuestionDependencyQuestionAnswerCondition
                    ).dependsOnQuestionId,
                    value: (cond as QuestionDependencyQuestionAnswerCondition)
                        .value,
                }));

                let isActive = false;
                for (let j = 0; j < dependsOnList.length; ++j) {
                    const dependsOn = dependsOnList[j];
                    const answerForDependency = updatedAnswers.find(
                        (answer) =>
                            answer.question.id ===
                            dependsOn.dependsOnQuestionId,
                    );
                    const answerValue = answerForDependency?.answerTags.find(
                        (ansTag) => ansTag.isActive,
                    )?.tag?.value;

                    if (answerValue === dependsOn.value) {
                        isActive = true;
                        break;
                    }
                }

                if (!isActive) {
                    answer.setAnswerTags([]);
                }
            }
        }

        let foundLevel = instance?.workflowInfo.workflowLevelInfos?.find(
            (_, idx) => idx === level,
        );
        if (instance && foundLevel) {
            let activeLevel: WorkflowUpdateInstanceLevelPayload = {
                workflowInstanceId: instance.workflowInfo.instanceId,
                result,
                note: note ?? "",
                workflowMessagingMetadataId:
                    foundLevel.workflowMessagingMetadataId,
                userId: this.workflowReviewStore.authStore._user.profile.sub,
                evaluationModuleId: workflowModuleId,
                evaluationModule: foundLevel.evaluationModule,
                conversationModule: null,
                createdBy:
                    this.workflowReviewStore.authStore._user.profile.email,
            };

            this.setupAsyncTask(UpdateWorkflow, async () => {
                try {
                    const answers = [
                        ...updatedAnswers,
                        ...(instance.workflowInfo.workflowLevelInfos[level]
                            .answers ?? []),
                    ];
                    switch (
                        this.workflowReviewStore.workflowReviewerInfo
                            ?.workflowType
                    ) {
                        case WorkflowConditionType.APT: {
                            const payload: AptWorkflowUpdatePayload = {
                                evaluationId:
                                    instance.workflowInfo.evaluationModule
                                        ?.evaluationId ?? "",
                                workflowInstanceId:
                                    instance.workflowInfo.workflowInstanceId,
                                workflowInstanceLevel: activeLevel,
                                answers,
                                aptModuleId:
                                    instance.workflowInfo.evaluationModule
                                        ?.id ?? "",
                                aptModuleScore: moduleScore,
                            };
                            await this.updateAptWorkflow(payload);
                            break;
                        }
                        case WorkflowConditionType.ScoreEscalation: {
                            if (
                                moduleScore === null ||
                                moduleScore === undefined
                            ) {
                                this.workflowReviewStore.messageStore.logError(
                                    "Module score not found",
                                );
                                return;
                            }
                            const payload: ScoreEscalationWorkflowUpdatePayload =
                                {
                                    evaluationId:
                                        instance.workflowInfo
                                            .scoreEscalationModule
                                            ?.evaluationId ?? "",
                                    workflowInstanceId:
                                        instance.workflowInfo
                                            .workflowInstanceId,
                                    workflowInstanceLevel: activeLevel,
                                    answers,
                                    scoreEscalationModuleId:
                                        instance.workflowInfo
                                            .scoreEscalationModule.id,
                                    scoreEscalationModuleScore:
                                        moduleScore ?? 0,
                                };
                            await this.updateScoreEscalationWorkflow(payload);
                            break;
                        }
                    }
                    await this.workflowReviewStore.getAllModules();
                    if (this.workflowReviewStore.workflowId) {
                        await this.workflowReviewStore.getWorkflowsForReviewer(
                            this.workflowReviewStore.workflowId,
                        );
                    }
                } catch {
                    RootStore()
                        .getStore(MessageStore)
                        .logError("Error updating workflow");
                }
            });
        }
    };

    /**
     * called on eval save-as-complete to remove a non boss workflow module that is incomplete
     * this is because the module and answers should not be saved if the user did not complete or reject the level
     *
     * @param evaluation
     */
    @action
    removeUncompletedNonBossModules(evaluation: Evaluation) {
        const instance =
            this.workflowReviewStore.workflowInfoWithAssignedWorkflowInstances;
        for (let level of instance?.workflowInfo.workflowLevelInfos ?? []) {
            //this level has a module but it is still pending
            if (level.evaluationModule && level.state === "pending") {
                evaluation.answers = evaluation.answers.filter(
                    (value) =>
                        value.evaluationModuleId !== level.evaluationModuleId,
                );

                evaluation.evaluationModules =
                    evaluation.evaluationModules.filter(
                        (value) => value.id !== level.evaluationModuleId,
                    );
            }
        }
    }

    /**
     * called on eval save-as-complete to append onto the instanceLevel array
     * levels that represent completed boss levels..since boss levels are completed as
     * modules, the levels need to be appended manually so the aging-view recognizes the workflow as complete.
     *
     * @param evaluation
     */

    // TODO: May need to implement for Dispute
    @action
    completeBossLevels(evaluation: Evaluation) {
        const instance =
            this.workflowReviewStore.workflowInfoWithAssignedWorkflowInstances;
        const activeLevel = instance?.workflowInfo.currentLevel ?? -1;
        const metadataForLevel =
            instance?.workflowInfo.workflowLevelInfos[activeLevel]
                ?.workflowMessagingMetadataId;

        if (
            !instance?.workflowInfo.workflowLevelInfos?.some(
                (value, idx) =>
                    idx < activeLevel &&
                    (value.result === WorkflowStore.RejectResult ||
                        value.result === WorkflowStore.CompletedResult),
            ) &&
            instance?.workflowInfo.bossEvalModuleId &&
            !instance?.workflowInfo.workflowLevelInfos?.some(
                (value) =>
                    value.workflowMessagingMetadataId === metadataForLevel,
            )
        ) {
        }
    }

    validate() {
        let isValid = true;

        const instances = this.workflowReviewStore
            .workflowInfoWithAssignedWorkflowInstances
            ? [
                  this.workflowReviewStore
                      .workflowInfoWithAssignedWorkflowInstances,
              ]
            : [];
        instances
            .filter(
                (value) =>
                    value.workflowInfo.implementationType ===
                    WorkflowConditionType.APT,
            )
            .forEach((instance) => {
                const level = instance.workflowInfo.workflowLevelInfos?.find(
                    (level) =>
                        level.result === null &&
                        level.evaluationModuleId === null,
                );

                if (level !== undefined) isValid = false;
            });

        return isValid;
    }

    isBossModuleForActiveInstance(workflowModule: EvalLicensedModule) {
        const instance =
            this.workflowReviewStore.workflowInfoWithAssignedWorkflowInstances;

        // TODO needs to handle dispute boss levels

        const activeLevel = instance?.workflowInfo.currentLevel ?? -1;
        const licensedModuleId =
            instance?.workflowInfo.workflowLevelInfos[activeLevel]
                ?.evaluationModuleId;

        return (
            licensedModuleId &&
            licensedModuleId === workflowModule.id &&
            instance?.workflowInfo.bossEvalModuleId ===
                workflowModule.evaluationModuleId &&
            !instance?.workflowInfo.workflowLevelInfos?.some(
                (value, idx) =>
                    idx < activeLevel &&
                    (value.result === WorkflowStore.RejectResult ||
                        value.result === WorkflowStore.CompletedResult),
            )
        );
    }

    @computed
    get disputeBossLevelExists() {
        if (!this.workflowReviewStore.evalId) {
            return false;
        }

        const disputeInstances = this.listDisputeInstances();

        if (!disputeInstances.length) {
            return false;
        }
        return this.disputeConditionAtBossLevel(disputeInstances);
    }

    disputeConditionAtBossLevel(
        disputeInstances:
            | WorkflowInfoAssignedWorkflowInstance
            | WorkflowInfoAssignedWorkflowInstance[],
    ): any {
        if (!Array.isArray(disputeInstances)) {
            disputeInstances = [disputeInstances];
        }

        const anyBossLevels = disputeInstances.map((disputeInstance) => {
            const meta =
                this.workflowMetadata[disputeInstance.workflowInfo.instanceId];
            const completedLevels =
                disputeInstance.workflowInfo.workflowLevelInfos?.length ?? 0;
            const totalLevels = meta?.length;

            const possiblyAtBossLevel = completedLevels >= totalLevels - 1;
            if (!possiblyAtBossLevel) {
                return false;
            }

            const finishedEarly =
                disputeInstance.workflowInfo.workflowLevelInfos?.some((wil) => {
                    const isComplete =
                        wil.result === WorkflowStore.CompletedResult;

                    return isComplete && !(completedLevels === totalLevels);
                });

            return !finishedEarly && possiblyAtBossLevel;
        });

        const allTrue = anyBossLevels.every((value) => value);
        const allFalse = anyBossLevels.every((value) => !value);

        if (allTrue) {
            return true;
        } else return !allFalse;
    }

    @computed
    get aptInstanceExists() {
        const instances = this.workflowReviewStore.workflowReviewerInfo;
        if (!instances) {
            return false;
        }

        const metadata =
            this.workflowMetadata[instances.workflowInfo.instanceId];
        // are they all APT, if yes, show the tab
        return !!metadata?.some(
            (value1) => value1.type === WorkflowConditionType.APT,
        );

        // [true,true,true,true] //show
        // [true,true,true,true,....,false] ==> show
        // [false,false,false,false] // dont show

        // show APT tab
        // const allTrue = isApt.every((value) => value);
        // const allFalse = isApt.every((value) => !value);

        // if (allTrue) {
        //     return true;
        // } else return !allFalse;
    }

    instanceTitle(
        instance: WorkflowInfoAssignedWorkflowInstance,
        type: WorkflowConditionType,
    ) {
        switch (type) {
            case WorkflowConditionType.APT:
                return `${this.workflowReviewStore.modulesNameById.get(
                    instance.workflowInfo.questionModuleId,
                )}`;
            case WorkflowConditionType.EvaluationDispute:
                return instance.workflowInfo.conditionName;
            case WorkflowConditionType.ScoreEscalation:
                return `${instance.workflowInfo.conditionName}`;
        }
    }

    instanceSubTitle(
        instance: WorkflowInfoAssignedWorkflowInstance,
        type: WorkflowConditionType,
    ) {
        switch (type) {
            case WorkflowConditionType.APT:
                return instance.workflowInfo.question;
            case WorkflowConditionType.EvaluationDispute:
                break;
            case WorkflowConditionType.ScoreEscalation:
                return `${
                    this.modulesNameById.get(
                        instance.workflowInfo.workflowLevelInfos[0]
                            .evaluationModuleId,
                    ) ?? ""
                }`;
        }
    }

    // @computed
    // get workflowInstances(): WorkflowInstances[] {
    //     const workflowEvalModules: EvalLicensedModule[] =
    //         this.workflowReviewStore.evalLicensedModules?.filter(
    //             (value) => value.lmType === LMType.Workflow,
    //         ) ?? [];

    //     const workflowMetadata = this.workflowMetadata;
    //     const instances = this.workflowReviewStore
    //         .workflowInfoWithAssignedWorkflowInstances
    //         ? [
    //               this.workflowReviewStore
    //                   .workflowInfoWithAssignedWorkflowInstances,
    //           ]
    //         : [];
    //     const projectedInstances = instances
    //         .filter(
    //             (value) =>
    //                 (value.workflowInfo.implementationType !==
    //                     WorkflowConditionType.APT &&
    //                     this.disputeConditionAtBossLevel(value)) ||
    //                 value.workflowInfo.implementationType ===
    //                     WorkflowConditionType.APT,
    //         )
    //         .map((instance, instanceIdx) => {
    //             const workflowInstances = {
    //                 name: instance.workflowInfo.conditionName,
    //                 id: instance.workflowInfo.id,
    //                 instanceId: instance.workflowInfo.instanceId,
    //                 title: this.instanceTitle(
    //                     instance,
    //                     instance.workflowInfo.implementationType!,
    //                 ),
    //                 subTitle: this.instanceSubTitle(
    //                     instance,
    //                     instance.workflowInfo.implementationType!,
    //                 ),
    //                 workflowType: instance.workflowInfo.implementationType!,
    //                 levels:
    //                     instance.workflowInfo.workflowLevelInfos?.map(
    //                         (instanceLevel, levelIdx) => {
    //                             const level =
    //                                 this.workflowReviewStore
    //                                     .workflowInfoResponse?.workflowInfo
    //                                     .workflowLevelInfos[levelIdx];
    //                             const state: WFRInstanceLevelState =
    //                                 instanceLevel.evaluationModuleId ===
    //                                     instance.workflowInfo
    //                                         .bossEvalModuleId &&
    //                                 instance.workflowInfo.bossEvalModuleId !==
    //                                     null
    //                                     ? "boss"
    //                                     : instanceLevel.result !== null
    //                                     ? "complete"
    //                                     : "pending";
    //                             return {
    //                                 levelType:
    //                                     instance.workflowInfo
    //                                         .implementationType,
    //                                 state,
    //                                 instanceId:
    //                                     instance.workflowInfo.instanceId,
    //                                 workflowEvalModule:
    //                                     instanceLevel.evaluationModuleId
    //                                         ? workflowEvalModules?.find(
    //                                               (value) => {
    //                                                   return (
    //                                                       value.evaluationModuleId ===
    //                                                       instanceLevel.evaluationModuleId
    //                                                   );
    //                                               },
    //                                           ) ?? null
    //                                         : null,

    //                                 notes: instanceLevel.note,
    //                                 result: instanceLevel.result as WFRInstanceLevelResult,
    //                                 completedBy: level?.completedBy,
    //                                 level: level?.level,
    //                                 metadataId:
    //                                     instanceLevel.workflowMessagingMetadataId,
    //                                 name: level?.levelName,
    //                             };
    //                         },
    //                     ) ?? [],
    //             };

    //             const activeLevelMetadatas =
    //                 workflowMetadata[instance.workflowInfo.instanceId];

    //             const activeLevelMetadata = activeLevelMetadatas?.find(
    //                 (value) =>
    //                     value.level === instance.workflowInfo.activeLevel,
    //             );

    //             if (
    //                 !workflowInstances.levels.some(
    //                     (value) =>
    //                         value.level === instance.workflowInfo.activeLevel,
    //                 ) &&
    //                 instance.workflowInfo.status === WorkflowStatus.Open
    //             ) {
    //                 const state: WFRInstanceLevelState =
    //                     activeLevelMetadata ===
    //                     activeLevelMetadatas[activeLevelMetadatas.length - 1]
    //                         ? "boss"
    //                         : "pending";

    //                 workflowInstances.levels.push({
    //                     levelType: instance.workflowInfo.implementationType,
    //                     state,
    //                     instanceId: instance.workflowInfo.instanceId,
    //                     workflowEvalModule: null,
    //                     result: null,
    //                     notes: "",
    //                     level: instance.workflowInfo.activeLevel,
    //                     metadataId: activeLevelMetadata!.id,
    //                     completedBy:
    //                         this.workflowReviewStore.user?.profile?.email ?? "",
    //                     name: activeLevelMetadata?.name ?? "",
    //                 });
    //             }

    //             return workflowInstances;
    //         });

    //     // just for last pending level, need to assign correct eval module if needed.  Make sure level does not share the same eval module
    //     for (let instance of projectedInstances) {
    //         let lastAddedLevel = instance.levels[instance.levels.length - 1];

    //         const activeLevelMetadatas =
    //             workflowMetadata[lastAddedLevel.instanceId];

    //         const activeLevelMetadata = activeLevelMetadatas?.find(
    //             (value) => value.level === lastAddedLevel.level,
    //         );

    //         if (
    //             activeLevelMetadata?.licensedModuleId &&
    //             !lastAddedLevel.workflowEvalModule
    //         ) {
    //             var projectedLevels = projectedInstances
    //                 .map((instance) => instance.levels)
    //                 .reduce((a, b) => a.concat(b));

    //             lastAddedLevel.workflowEvalModule =
    //                 this.findUnusedEvaluationWorkflowModule(
    //                     activeLevelMetadata,
    //                     workflowEvalModules,
    //                     projectedLevels,
    //                 );
    //         }
    //     }

    //     projectedInstances.forEach((value: any) => {
    //         value.levels = value.levels
    //             .slice()
    //             .sort((a, b) => (a.level > b.level ? -1 : 1));
    //     });

    //     return projectedInstances;
    // }

    // level 0,1,2
    // metaLevels.length = 3
    pruneEarlyCompleteDisputeInstances(
        instances: WorkflowInfoAssignedWorkflowInstance[],
    ) {
        return instances.filter((instance) => {
            const metaLevels =
                this.workflowMetadata[instance.workflowInfo.instanceId];
            if (
                instance.workflowInfo.implementationType !==
                WorkflowConditionType.APT
            ) {
                const isCompleteBeforeBoss =
                    instance.workflowInfo.workflowLevelInfos?.some(
                        (level, idx) =>
                            level.result === WorkflowStore.CompletedResult &&
                            metaLevels?.length - 1 > idx,
                    );

                return !isCompleteBeforeBoss;
            }
            return true;
        });
    }

    private listDisputeInstances(): WorkflowInfoAssignedWorkflowInstance[] {
        const instances = this.workflowReviewStore
            .workflowInfoWithAssignedWorkflowInstances
            ? [
                  this.workflowReviewStore
                      .workflowInfoWithAssignedWorkflowInstances,
              ]
            : [];
        return instances.filter((value) => {
            return [
                WorkflowConditionType.EvaluationDispute,
                WorkflowConditionType.ScoreEscalation,
            ].includes(value.workflowInfo.implementationType!);
        });
    }

    private findUnusedEvaluationWorkflowModule(
        metaData: WorkflowMetadata,
        evalLicenceModules: EvalLicensedModule[],
        workflowLevelProps: WFRInstanceLevelProps[],
    ): EvalLicensedModule | null {
        //get list of modules with the same licence module id as the one we are looking for
        var modules = evalLicenceModules.filter(
            (module) => module.id === metaData.licensedModuleId,
        );

        //if a level has a modules eval module Id, it is one already being used
        for (let module of modules) {
            var found = false;

            for (let levelProp of workflowLevelProps) {
                if (
                    levelProp.workflowEvalModule?.evaluationModuleId ===
                    module.evaluationModuleId
                ) {
                    found = true;

                    continue;
                }
            }

            if (found) continue;
            return module;
        }

        //unused module was not found, return null for default workflow module
        return null;
    }

    @action
    public addIntermediateLevelToDisputeInstance = () => {
        const disputeInstances = this.listDisputeInstances();

        for (let disputeInstance of disputeInstances) {
            const metaLevels =
                this.workflowMetadata[disputeInstance.workflowInfo.instanceId];

            const {
                workflowInfo: { activeLevel },
            } = disputeInstance;

            if (metaLevels.length - 1 === activeLevel) {
                // delete to this.CompleteBossLevels for  adding boss level
                continue;
            }

            if (!disputeInstance.workflowInfo.workflowLevelInfos?.length) {
                // create array and add level
                // TODO: Refactor response
                // disputeInstance.workflowInfo.workflowLevelInfos = [
                //     {
                //         workflowMessagingMetadataId: metadataForLevel.id,
                //         result: null,
                //         createdOn: moment().local().toISOString(),
                //         createdBy:
                //             this.workflowReviewStore.user?.profile?.email,
                //         userId: this.workflowReviewStore.user?.profile?.sub,
                //         workflowInstanceId: disputeInstance.id,
                //         evaluationModuleId: metadataForLevel.licensedModuleId
                //             ? disputeInstance?.bossEvalModuleId ?? ""
                //             : "",
                //         evaluationModule: disputeInstance.evaluationModule,
                //         note: "",
                //     },
                // ];
            } else {
                if (
                    !disputeInstance.workflowInfo.workflowLevelInfos.some(
                        (value, idx) =>
                            idx === disputeInstance.workflowInfo.activeLevel,
                    ) &&
                    disputeInstance.workflowInfo.status !==
                        WorkflowStatus.Closed
                ) {
                    // TODO: Refactor response
                    // disputeInstance.workflowInfo.workflowLevelInfos.push({
                    //     workflowMessagingMetadataId: metadataForLevel.id,
                    //     result: null,
                    //     createdOn: moment().local().toISOString(),
                    //     createdBy:
                    //         this.workflowReviewStore.user?.profile?.email,
                    //     userId: this.workflowReviewStore.user?.profile?.sub,
                    //     workflowInstanceId: disputeInstance.id,
                    //     evaluationModuleId: metadataForLevel.licensedModuleId
                    //         ? disputeInstance?.bossEvalModuleId ?? ""
                    //         : "",
                    //     evaluationModule: disputeInstance.evaluationModule,
                    //     note: "",
                    // });
                }
            }
        }
    };
}
