import { Button, styled } from "@mui/material";

import theme from "Theme/AppTheme";

const AcxClickableCell = styled(Button)({
    color: theme.palette.gray[700],
    fontWeight: 400,
    height: "100%",
    width: "100%",
    justifyContent: "flex-start",
    verticalAlign: "unset",
    textDecoration: "underline",
    padding: theme.spacing(0, 2),

    "&:hover": {
        textDecoration: "underline",
    },
});

export default AcxClickableCell;
