import {
    CancelPresentationRounded,
    ControlPointDuplicateRounded,
    DeleteRounded,
    DownloadRounded,
    EditRounded,
    PlayCircleOutlineRounded,
    PushPin,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    Grid,
    IconButton,
    keyframes,
    styled,
    Theme,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import theme from "Theme/AppTheme";
import AppliedFiltersChips from "components/ApplicationFilters/AppliedFiltersChips";
import {
    DateRangeFilter,
    HierarchiesFilter,
} from "components/ApplicationFilters/Filters";
import ManageFiltersButton from "components/ApplicationFilters/ManageFiltersButton";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxButton from "components/UI/AcxButton";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxToggleButton from "components/UI/AcxToggleButton";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import AcxMenu, {
    AcxMenuItemProps,
    StyledMenuLabel,
} from "components/UI/Menu/AcxMenu";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Options } from "react-to-pdf";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import { LayoutDrawerStore } from "stores/Layout/LayoutDrawerStore";
import { useStore } from "utils/useStore";
import { SignalsDomain } from "../Models/SignalsModels";
import SignalsReportSelectorStore from "../Store/SignalsReportSelectorStore";
import SignalsReportStore, {
    LoadingSignalsRefresh,
} from "../Store/SignalsReportStore";
import CustomizeDashboard from "./CustomizeDashboard";

const useStyles = makeStyles((theme: Theme) => ({
    rootHeaderContainer: {
        position: "sticky",
        top: "8px",
        padding: theme.spacing(2),
        width: "100%",
        borderRadius: 0,
        zIndex: 1098,
        backgroundColor: theme.palette.lightgray.background,
    },
    headerText: {
        fontSize: "24px",
        fontWeight: "bold",
        marginRight: theme.spacing(1),
    },
    clearButton: {
        color: theme.palette.blue.main,
        "&:hover": {
            backgroundColor: theme.palette.white.main,
        },
    },
    mainContainer: {
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
        border: `1px solid ${theme.palette.grey[300]}`,
    },
    addButton: {
        color: theme.palette.blackFont.main,
        backgroundColor: theme.palette.white.main,
        width: "115px",
        border: "1px solid",
        borderColor: theme.palette.gray.light,
        height: "32px",
        "&:hover": {
            backgroundColor: theme.palette.gray.light,
        },
    },
    menuLabelText: {
        fontSize: "13px",
        letterSpacing: "0",
        lineHeight: "20px",
        color: "inherit",
    },
    cancelButton: {
        color: theme.palette.red.main,
        borderColor: theme.palette.lightgrayBorder.main,
        backgroundColor: theme.palette.white.main,
        "&:hover": {
            borderColor: theme.palette.lightgrayBorder.main,
            backgroundColor: theme.palette.white.main,
            opacity: "75%",
        },
    },
}));

const loading = keyframes`
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
`;

const SkeletonDiv = styled("div")`
    width: 80px;
    height: 80px;

    background-color: #e5e7eb;
    border-radius: 8px;
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.2) 25%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0.2) 75%
        );
        animation: ${loading} 1.5s infinite;
    }
`;

type LogoBlobInfo = { blobSasUrl: string; fileName: string };

type Props = {
    domainName: SignalsDomain;
    showFilters: boolean;
    toPDF: (options?: Options) => void;
};

const SignalsHeader: React.FC<Props> = observer((props: Props) => {
    const signalsReportStore = useStore(SignalsReportStore);

    const applicationFiltersStore = useStore(ApplicationFiltersStore);
    const messageStore = useStore(MessageStore);
    const selectorStore = useStore(SignalsReportSelectorStore);
    const drawerStore = useStore(LayoutDrawerStore);
    const navigate = useNavigate();

    const classes = useStyles();

    const headerTextByDomain: { [K in SignalsDomain]: string | undefined } = {
        eddy: "Eddy Reports",
        trends: "Trends Highlights",
        topics: "Themes and Topics",
        contacts: "Contact Types",
        dashboard: signalsReportStore.currentDashboard?.title,
    };

    const [dashboardLogo, setDashboardLogo] = useState<
        LogoBlobInfo | undefined
    >();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isHovered, setIsHovered] = useState(false);
    const [editingDashTitle, setEditingDashTitle] = useState(false);
    const [dashboardTitle, setDashboardTitle] = useState(
        headerTextByDomain[props.domainName],
    );

    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showDupEdit, setShowDupEdit] = useState(false);

    const [isLogoLoaded, setIsLogoLoaded] = useState(false);
    const [savePDFOpen, setSavePDFOpen] = useState(false);

    const newDashVisItems = [
        { value: "User", element: "Only Me" },
        {
            value: "Organization",
            element: "My Organization",
        },
    ];

    const isCustomDashboard = props.domainName === "dashboard";
    const dashboardHasPinnedFilter =
        isCustomDashboard &&
        !!signalsReportStore.currentDashboard?.pinnedApplicationFilterId;
    // Disabled if and only if:
    // - viewing a custom dashboard
    // - not the owner of the viewed dashboard
    // - there is a filter pinned
    const isDashboardNonEditableAndHasPinnedFilter =
        !signalsReportStore.dashboardIsEditable && dashboardHasPinnedFilter;

    function handleMoreMenuClose() {
        setAnchorEl(null);
    }

    function handleMoreMenuClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    const handleClickApply = async () => {
        // Save filter state when they apply
        applicationFiltersStore.saveCurrentFilterState("Signals");
        // assume the user has changed a top filter, may need to be smarter about that
        signalsReportStore.allChartData.forEach((c) => (c.shouldReload = true));

        if (props.domainName !== "topics") {
            signalsReportStore.refreshSignalsCards();
        } else {
            signalsReportStore.getTopicsOnTheRise();
        }

        signalsReportStore.refreshReports();
    };

    function toggleCustomizeDashboardDrawer() {
        if (!signalsReportStore.currentDashboard?.id) {
            return;
        }

        drawerStore.closeAndResetDrawer();
        drawerStore.restoreDefaults();
        drawerStore.setOffsetPixels(72);
        drawerStore.setCustomRootStyles({ zIndex: 1099 });
        drawerStore.setContentFactory(() => (
            <CustomizeDashboard
                dashboardId={signalsReportStore.currentDashboard!.id}
                currentName={signalsReportStore.currentDashboard!.title}
                currentVisibility={
                    signalsReportStore.currentDashboard!.visibility ?? "User"
                }
                currentPinnedFilter={applicationFiltersStore.getSavedFilterById(
                    signalsReportStore.currentDashboard
                        ?.pinnedApplicationFilterId,
                )}
                currentLogoName={signalsReportStore.currentDashboard!.logoName}
                updateDashboard={signalsReportStore.updateDashboard}
                closeDrawer={() => drawerStore.setOpen(false)}
            />
        ));

        drawerStore.setOpen(true);
    }

    useEffect(() => {
        // a safety check
        if (!applicationFiltersStore || !messageStore) return;

        if (applicationFiltersStore.hasFilterStateChangedSince("Signals"))
            messageStore.logInfo(
                "Your filters have been updated. Click 'Apply' to refresh the results.",
            );

        return () => {
            // Leveraging the useEffect cleanup function, we will save the current
            // filter state (if not present) when this component is unmounted. Sort of
            // like a page leave listener
            if (applicationFiltersStore.savedFilterStates["Signals"]) return;
            applicationFiltersStore.saveCurrentFilterState("Signals");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setDashboardTitle(headerTextByDomain[props.domainName]);
        selectorStore.savePDFName = headerTextByDomain[props.domainName] ?? "";

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.domainName, signalsReportStore.currentDashboard?.title]);

    useEffect(() => {
        async function getLogo() {
            setIsLogoLoaded(false);
            setDashboardLogo(undefined);
            if (!signalsReportStore.currentDashboard?.id) return;

            const logo = await signalsReportStore.signalsService.getLogo(
                signalsReportStore.currentDashboard!.id,
            );

            if (logo) {
                setDashboardLogo(logo);
            }
        }

        getLogo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        signalsReportStore.currentDashboard?.id,
        signalsReportStore.currentDashboard?.logoName,
        signalsReportStore.currentDashboard,
    ]);

    const menuItems: AcxMenuItemProps[] = [];

    if (signalsReportStore.isPresentationMode) {
        menuItems.push({
            id: "signals-dashboard-present",
            props: {
                onClick: () => {
                    signalsReportStore.isPresentationMode = false;
                    handleMoreMenuClose();
                },
            },
            label: (
                <StyledMenuLabel className={classes.menuLabelText}>
                    Exit Presentation
                </StyledMenuLabel>
            ),
            icon: <CancelPresentationRounded />,
        });
    } else {
        menuItems.push({
            id: "signals-dashboard-present",
            props: {
                onClick: () => {
                    signalsReportStore.isPresentationMode = true;
                    handleMoreMenuClose();
                },
            },
            label: (
                <StyledMenuLabel className={classes.menuLabelText}>
                    Present
                </StyledMenuLabel>
            ),
            icon: <PlayCircleOutlineRounded />,
        });
        if (isCustomDashboard) {
            if (signalsReportStore.dashboardIsEditable) {
                menuItems.push({
                    id: "signals-dashboard-edit",
                    props: {
                        onClick: () => {
                            toggleCustomizeDashboardDrawer();
                            handleMoreMenuClose();
                        },
                    },
                    label: (
                        <StyledMenuLabel className={classes.menuLabelText}>
                            Edit
                        </StyledMenuLabel>
                    ),
                    icon: <EditRounded />,
                });
            }

            menuItems.push({
                id: "signals-dashboard-duplicate",
                props: {
                    onClick: () => {
                        setShowDupEdit(true);

                        signalsReportStore.newDashboardTitle =
                            "Copy of " + headerTextByDomain[props.domainName];

                        handleMoreMenuClose();
                    },
                },
                label: (
                    <StyledMenuLabel className={classes.menuLabelText}>
                        Duplicate
                    </StyledMenuLabel>
                ),
                icon: <ControlPointDuplicateRounded />,
            });
        }
    }

    menuItems.push({
        id: "signals-dashboard-save-as-PDF",
        props: {
            onClick: () => {
                setSavePDFOpen(true);
                handleMoreMenuClose();
            },
        },
        label: (
            <StyledMenuLabel className={classes.menuLabelText}>
                Save as PDF
            </StyledMenuLabel>
        ),
        icon: <DownloadRounded />,
        lastItemOfSection:
            !signalsReportStore.isPresentationMode && isCustomDashboard,
    });

    if (
        signalsReportStore.dashboardIsEditable &&
        !signalsReportStore.isPresentationMode &&
        isCustomDashboard
    ) {
        menuItems.push({
            id: "signals-dashboard-delete",
            props: {
                onClick: () => {
                    setShowConfirmDelete(true);
                    handleMoreMenuClose();
                },
            },
            label: (
                <StyledMenuLabel className={classes.menuLabelText}>
                    Delete
                </StyledMenuLabel>
            ),
            icon: <DeleteRounded />,
        });
    }

    const enableEditDashboardTitle =
        isCustomDashboard &&
        signalsReportStore.dashboardIsEditable &&
        !signalsReportStore.isPresentationMode;

    const duplicateDashboardWrapper = async () => {
        setShowDupEdit(false);

        const dashCreateRes = await signalsReportStore.duplicateDashboard(
            signalsReportStore.newDashboardTitle,
            signalsReportStore.newDashboardVis,
            signalsReportStore.currentDashboard?.logoName,
        );
        if (dashCreateRes) {
            navigate("/app/signals/dashboard/" + dashCreateRes.id);
        }
    };

    const deleteDashboard = () => {
        if (!signalsReportStore.currentDashboard?.id) return;

        try {
            signalsReportStore.signalsService.deleteDashboard({
                id: signalsReportStore.currentDashboard.id,
            });
            signalsReportStore.allDashboardsUserCanView =
                signalsReportStore.allDashboardsUserCanView.filter(
                    (d) => d.id !== signalsReportStore.currentDashboard?.id,
                );
            signalsReportStore.messageStore.logMessage(
                "Dashboard successfully deleted",
                "success",
            );
            setShowConfirmDelete(false);
            navigate("/app/signals");
        } catch (e) {
            signalsReportStore.messageStore.logMessage(
                "Failed to delete dashboard",
                "error",
            );
        }
    };

    const addReportComponent = signalsReportStore.dashboardIsEditable ? (
        <Grid item>
            <AcxButton
                id="signals-dashboard-add-report"
                fullWidth
                onClick={() => (selectorStore.showReportSelector = true)}
                size="small"
                startIcon={<AddIcon />}
                className={classes.addButton}
            >
                Add Report
            </AcxButton>
        </Grid>
    ) : (
        <Grid
            container
            item
            xs={3}
            alignItems={"center"}
            style={{
                color: theme.palette.gray.main,
                fontWeight: "600",
            }}
        >
            <VisibilityIcon
                style={{
                    marginRight: theme.spacing(1),
                }}
            />
            View Only
        </Grid>
    );

    return (
        <Grid
            container
            className={classes.rootHeaderContainer}
            style={{ top: signalsReportStore.isPresentationMode ? 0 : "8px" }}
        >
            <Grid
                container
                alignItems={"center"}
                justifyContent={"space-between"}
                style={{ marginBottom: "16px" }}
            >
                <Grid container item xs={8}>
                    {isCustomDashboard &&
                        !!signalsReportStore.currentDashboard?.logoName && (
                            <Grid
                                item
                                display={"flex"}
                                alignItems={"center"}
                                height={80}
                                width={80}
                                sx={{
                                    "& img": { display: "none" },
                                    "& img.loaded": { display: "block" },
                                }}
                            >
                                {!isLogoLoaded && <SkeletonDiv />}
                                <img
                                    src={dashboardLogo?.blobSasUrl}
                                    alt={""}
                                    onLoad={() => setIsLogoLoaded(true)}
                                    style={{
                                        display: isLogoLoaded
                                            ? "block"
                                            : "none",
                                        height: "80px",
                                        width: "80px",
                                        borderRadius: "8px",
                                    }}
                                />
                            </Grid>
                        )}
                    <Grid
                        item
                        display={"flex"}
                        alignItems={"center"}
                        marginLeft={
                            signalsReportStore.currentDashboard?.logoName
                                ? theme.spacing(2)
                                : 0
                        }
                    >
                        <Typography
                            variant="h1"
                            style={{
                                fontSize: "24px",
                                cursor: enableEditDashboardTitle
                                    ? "pointer"
                                    : "auto",
                            }}
                            onMouseEnter={
                                enableEditDashboardTitle
                                    ? () => setIsHovered(true)
                                    : undefined
                            }
                            onMouseLeave={
                                enableEditDashboardTitle
                                    ? () => setIsHovered(false)
                                    : undefined
                            }
                            onClick={
                                enableEditDashboardTitle
                                    ? () => setEditingDashTitle(true)
                                    : undefined
                            }
                        >
                            {editingDashTitle && enableEditDashboardTitle ? (
                                <AcxMainTextField
                                    id="edit-dashboard-title-input"
                                    value={dashboardTitle}
                                    onChange={(e) => {
                                        setDashboardTitle(e.target.value);
                                    }}
                                    onBlur={async () => {
                                        setEditingDashTitle(false);
                                        if (
                                            !signalsReportStore.currentDashboard
                                                ?.visibility
                                        )
                                            return;

                                        const res =
                                            await signalsReportStore.signalsService.updateDashboard(
                                                {
                                                    id: signalsReportStore
                                                        .currentDashboard.id,
                                                    title: dashboardTitle ?? "",
                                                    visibility:
                                                        signalsReportStore
                                                            .currentDashboard
                                                            .visibility,
                                                    pinnedApplicationFilterId:
                                                        signalsReportStore
                                                            .currentDashboard
                                                            .pinnedApplicationFilterId,
                                                },
                                            );
                                        const updatedDash =
                                            signalsReportStore.allDashboardsUserCanView.find(
                                                (ud) =>
                                                    ud.id ===
                                                    signalsReportStore
                                                        .currentDashboard?.id,
                                            );
                                        if (updatedDash) {
                                            updatedDash.title = res.title;
                                        }
                                    }}
                                    blurOnEnterPress
                                />
                            ) : (
                                dashboardTitle
                            )}
                        </Typography>
                    </Grid>

                    {isHovered &&
                        !editingDashTitle &&
                        enableEditDashboardTitle && (
                            <Grid item display={"flex"} alignItems={"center"}>
                                <EditIcon
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        color: theme.palette.gray.main,
                                        marginLeft: "4px",
                                        marginTop: "2px",
                                    }}
                                />
                            </Grid>
                        )}
                </Grid>

                <Grid
                    container
                    item
                    xs={3}
                    justifyContent={"flex-end"}
                    gap={2}
                    alignItems={"center"}
                >
                    {!signalsReportStore.isPresentationMode &&
                        isCustomDashboard &&
                        addReportComponent}
                    <Grid item>
                        <IconButton
                            id={"signals-more-icon-header"}
                            style={{
                                color: theme.palette.black.main,
                                border: "1px solid",
                                borderColor: theme.palette.gray.light,
                                borderRadius: theme.shape.borderRadius,
                                backgroundColor: theme.palette.white.main,
                            }}
                            onClick={handleMoreMenuClick}
                            size="small"
                        >
                            <MoreHorizIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <AcxMenu
                    menuItems={menuItems}
                    anchorElement={anchorEl}
                    onMenuClose={handleMoreMenuClose}
                />
            </Grid>

            <AcxDialog
                isOpen={showConfirmDelete}
                onClose={() => {
                    setShowConfirmDelete(false);
                }}
                title="Confirm Delete Dashboard"
                subTitle={`Are you sure you want to delete dashboard '${dashboardTitle}'?`}
                children={
                    <Grid container item xs={4}>
                        <AcxButton
                            fullWidth
                            className={classes.addButton}
                            onClick={deleteDashboard}
                        >
                            Confirm Delete
                        </AcxButton>
                    </Grid>
                }
            />

            {/* Duplicate Dashboard Dialog */}
            <AcxDialog
                isOpen={showDupEdit}
                onClose={() => {
                    setShowDupEdit(false);
                }}
                title={"Duplicate Dashboard"}
                subTitle={`Create a copy of this dashboard`}
                dialogContentChildren={
                    <Grid container style={{ width: "40vh" }} spacing={2}>
                        <Grid item xs={12}>
                            <AcxMainTextField
                                id="duplicate-or-edit-dashboard-title-input"
                                labelText="Dashboard Name"
                                value={signalsReportStore.newDashboardTitle}
                                onChange={(e) =>
                                    (signalsReportStore.newDashboardTitle =
                                        e.target.value)
                                }
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AcxToggleButton
                                id="dashboard-visibility-toggle"
                                items={newDashVisItems}
                                defaultValue={
                                    newDashVisItems.find(
                                        (i) =>
                                            i.value ===
                                            signalsReportStore.newDashboardVis,
                                    )?.value
                                }
                                inputLabel="Visibility"
                                onChange={(item) => {
                                    if (!item) return;
                                    signalsReportStore.newDashboardVis = item;
                                }}
                                exclusive
                            />
                        </Grid>
                    </Grid>
                }
                children={
                    <Grid container item xs={6}>
                        <AcxButton
                            fullWidth
                            color="primary"
                            onClick={() => {
                                duplicateDashboardWrapper();
                            }}
                            disabled={!signalsReportStore.newDashboardTitle}
                        >
                            {"Duplicate Dashboard"}
                        </AcxButton>
                    </Grid>
                }
            />

            <AcxDialog
                title={"Save as PDF"}
                subTitle={""}
                dialogContentChildren={
                    <Grid container style={{ width: "40vh" }}>
                        <Grid item xs={12}>
                            <AcxMainTextField
                                id="save"
                                value={selectorStore.savePDFName}
                                onChange={(e) => {
                                    selectorStore.savePDFName = e.target.value;
                                }}
                                labelText="File Name"
                            />
                        </Grid>
                    </Grid>
                }
                isOpen={savePDFOpen}
                onClose={() => setSavePDFOpen(false)}
                disableEnforceFocus
                children={
                    <Grid container item xs={4}>
                        <AcxButton
                            fullWidth
                            color="primary"
                            onClick={() => {
                                props.toPDF();
                                setSavePDFOpen(false);
                            }}
                            containerStyle={{
                                width: "415px",
                            }}
                        >
                            Save as PDF
                        </AcxButton>
                    </Grid>
                }
                hideCancelButton
            />

            {props.showFilters && (
                <Grid
                    container
                    direction="column"
                    spacing={0.5}
                    className={classes.mainContainer}
                >
                    <Grid
                        container
                        item
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid
                            container
                            item
                            alignItems="center"
                            direction="row"
                            flexWrap="nowrap"
                        >
                            <Grid
                                container
                                item
                                columnSpacing={1}
                                alignItems="center"
                                justifyContent="space-between"
                                flexWrap="nowrap"
                            >
                                <Grid
                                    container
                                    item
                                    spacing={1}
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <ManageFiltersButton
                                            title="Filters"
                                            subtitle="Define the criteria that will determine which conversations are included or excluded in Signals reporting."
                                            savedFilterDomains={
                                                ApplicationFiltersStore.AllSavedFilterDomains
                                            }
                                            onApply={handleClickApply}
                                            disabled={
                                                isDashboardNonEditableAndHasPinnedFilter
                                            }
                                        />
                                    </Grid>

                                    <Grid item>
                                        <DateRangeFilter
                                            store={
                                                applicationFiltersStore.quickFiltersStore
                                            }
                                            filterKey="dateRange"
                                            maxWidth="250px"
                                            onSelect={() => {
                                                if (dashboardHasPinnedFilter)
                                                    return;
                                                applicationFiltersStore.alertOfQuickFilterOverride(
                                                    "dateRange",
                                                );
                                            }}
                                            viewOnly={
                                                isDashboardNonEditableAndHasPinnedFilter
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <HierarchiesFilter
                                            store={
                                                applicationFiltersStore.quickFiltersStore
                                            }
                                            filterKey="hierarchyIds"
                                            onSaveUpdateWithHierarchies={() => {
                                                if (dashboardHasPinnedFilter)
                                                    return;
                                                applicationFiltersStore.alertOfQuickFilterOverride(
                                                    "hierarchyIds",
                                                );
                                            }}
                                            viewOnly={
                                                isDashboardNonEditableAndHasPinnedFilter
                                            }
                                            placeholder={
                                                isDashboardNonEditableAndHasPinnedFilter
                                                    ? "No hierarchies applied"
                                                    : undefined
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        alignItems="center"
                                        columnSpacing={1 / 2}
                                        width="fit-content"
                                        flexWrap="nowrap"
                                    >
                                        {!isDashboardNonEditableAndHasPinnedFilter &&
                                            (applicationFiltersStore.appliedFilterCount >
                                                0 ||
                                                applicationFiltersStore
                                                    .quickFiltersStore
                                                    .appliedFilterCount >
                                                    0) && (
                                                <Grid item>
                                                    <AcxButton
                                                        id="signals-filters-clear"
                                                        variant="text"
                                                        onClick={() => {
                                                            applicationFiltersStore.resetFilters();
                                                            applicationFiltersStore.setSelectedSavedFilters(
                                                                undefined,
                                                            );
                                                            applicationFiltersStore.quickFiltersStore.resetFilters();
                                                        }}
                                                        sx={{
                                                            color: theme.palette
                                                                .blue.main,
                                                            ":hover": {
                                                                backgroundColor:
                                                                    theme
                                                                        .palette
                                                                        .white
                                                                        .main,
                                                            },
                                                        }}
                                                    >
                                                        Clear
                                                    </AcxButton>
                                                </Grid>
                                            )}
                                        <Grid item>
                                            <AcxButton
                                                id="signals-filters-apply"
                                                color="primary"
                                                onClick={handleClickApply}
                                                loading={
                                                    signalsReportStore.getTaskLoading(
                                                        "refresh signals",
                                                    ) ||
                                                    signalsReportStore.anyTaskLoading
                                                }
                                            >
                                                Apply
                                            </AcxButton>
                                        </Grid>
                                        {signalsReportStore.getTaskLoading(
                                            LoadingSignalsRefresh,
                                        ) && (
                                            <Grid item>
                                                <AcxButton
                                                    id="signals-filters-cancel"
                                                    variant="outlined"
                                                    onClick={() =>
                                                        signalsReportStore.cancelReportRequests()
                                                    }
                                                    sx={(theme) => ({
                                                        color: theme.palette.red
                                                            .main,
                                                        borderColor:
                                                            theme.palette
                                                                .lightgrayBorder
                                                                .main,
                                                        backgroundColor:
                                                            theme.palette.white
                                                                .main,
                                                        ":hover": {
                                                            borderColor:
                                                                theme.palette
                                                                    .lightgrayBorder
                                                                    .main,
                                                            backgroundColor:
                                                                theme.palette
                                                                    .white.main,
                                                            opacity: "75%",
                                                        },
                                                    })}
                                                >
                                                    Cancel
                                                </AcxButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                {isCustomDashboard &&
                                    (signalsReportStore.dashboardIsEditable ||
                                        signalsReportStore.currentDashboard
                                            ?.pinnedApplicationFilterId) &&
                                    applicationFiltersStore.selectedSavedFilter && (
                                        <Grid item>
                                            <IconButton
                                                onClick={() => {
                                                    signalsReportStore.toggleCurrentDashboardPinnedFilter(
                                                        applicationFiltersStore
                                                            .selectedSavedFilter
                                                            ?.id,
                                                    );
                                                }}
                                                disabled={
                                                    !signalsReportStore.dashboardIsEditable ||
                                                    signalsReportStore.getTaskLoading(
                                                        "Pin Filter",
                                                    )
                                                }
                                                sx={(theme) => {
                                                    let color = "default";

                                                    const pinnedFilterId =
                                                        signalsReportStore
                                                            .currentDashboard
                                                            ?.pinnedApplicationFilterId;
                                                    const selectedFilterId =
                                                        applicationFiltersStore
                                                            .selectedSavedFilter
                                                            ?.id;

                                                    if (
                                                        pinnedFilterId ===
                                                        selectedFilterId
                                                    )
                                                        color =
                                                            theme.palette
                                                                .primary.main;

                                                    return {
                                                        color,
                                                        rotate: "45deg",
                                                        ":disabled": {
                                                            color,
                                                        },
                                                    };
                                                }}
                                            >
                                                <PushPin />
                                            </IconButton>
                                        </Grid>
                                    )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <AppliedFiltersChips
                            disabled={isDashboardNonEditableAndHasPinnedFilter}
                        />
                    </Grid>
                </Grid>
            )}
            {signalsReportStore.isPresentationMode && (
                <Grid
                    container
                    style={{ fontWeight: 700, color: theme.palette.gray[500] }}
                >
                    Insights time frame:{" "}
                    {signalsReportStore.applicationFiltersStore.quickFiltersStore.startDate!.format(
                        "M/D/YYYY",
                    )}{" "}
                    -{" "}
                    {signalsReportStore.applicationFiltersStore.quickFiltersStore.endDate!.format(
                        "M/D/YYYY",
                    )}
                </Grid>
            )}
        </Grid>
    );
});
export default SignalsHeader;
