import { Grid, TablePagination } from "@mui/material";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import SignalsReportStore from "../Store/SignalsReportStore";
import { useStore } from "utils/useStore";

interface Props {
    handleChangePage: (page: number) => void;
    dataGridStore: AcxDataGridStore;
    itemCount: number;
    localPage: number;
}

const SignalsPagination: FunctionComponent<Props> = observer((props) => {
    const store = useStore(SignalsReportStore);

    return (
        <Grid item container justifyContent="flex-end">
            <TablePagination
                component="div"
                count={store.calculateEffectiveItems(
                    props.itemCount,
                    props.dataGridStore.selectedRowIds.length,
                )}
                page={props.localPage}
                onPageChange={(e, page) => {
                    props.handleChangePage(page);
                }}
                rowsPerPage={5}
                rowsPerPageOptions={[5]}
                showFirstButton
                showLastButton
            />
        </Grid>
    );
});

export default SignalsPagination;
