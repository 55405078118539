import { observer } from "mobx-react";
import React from "react";
import { Grid } from "@mui/material";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";

const SmallScreenWarning = observer(() => {
    return (
        <Grid item container spacing={1}>
            <Grid
                item
                xs={12}
                container
                justifyContent={"center"}
                alignContent={"center"}
            >
                <AspectRatioIcon fontSize="large" color="disabled" />
            </Grid>
            <Grid
                item
                xs={12}
                container
                justifyContent={"center"}
                alignContent={"center"}
                style={{ fontWeight: 600, textAlign: "center" }}
            >
                This screen size is currently not supported
            </Grid>
            <Grid
                item
                xs={12}
                container
                justifyContent={"center"}
                alignContent={"center"}
                style={{ textAlign: "center" }}
            >
                Please switch to desktop or expand your screen to view.
            </Grid>
        </Grid>
    );
});

export default SmallScreenWarning;
