import RBCFilterStore, {
    IRBCFilter,
    initRBCFilterItems,
} from "components/Conversations/Views/Drawer/components/DeepFilter/RBCFilterStore";
import WordsPhrasesStore, {
    IWordsPhrasesModel,
    initWordsPhrasesItems,
} from "components/Conversations/Views/Drawer/components/DeepFilter/WordsPhrasesStore";
import _ from "lodash";
import { action, computed, makeObservable, observable, toJS } from "mobx";
import Agentv2 from "models/Agentv2";
import moment from "moment";
import AgentService from "services/AgentService";
import { BaseStore } from "stores/BaseStore";
import {
    DatePickerComponentStore,
    DateReferenceOption,
    getDateFromDateRangeLabel,
} from "stores/ComponentStores/DatePickerComponentStore";
import { IRootStore } from "stores/RootStore";
import { toIsoByDateReference } from "utils/DateTimeUtils";
import type {
    ApplicationFilters,
    ContactTypeOption,
    DataLabel,
    DateRangeLabel,
    SimpleTopicCluster,
} from "./ApplicationFiltersStore";
import { CallDirection } from "./Filters/CallDirection";
import { EvaluationType } from "./Filters/EvaluationType";
import { HipaaCompliance } from "./Filters/HipaaCompliance";
import { MediaType } from "./Filters/MediaType";
import { SafetyEvent } from "./Filters/SafetyEvent";
import { Sentiment } from "./Filters/Sentiment";

export class ApplicationFiltersInstanceStore
    extends BaseStore
    implements ApplicationFilters
{
    public static Tasks = {
        VALIDATE_WORDS_AND_PHRASES: "Validate Words and Phrases",
        LOAD_AGENTS: "Load Agents",
    } as const;

    datePickerStore: DatePickerComponentStore;
    readonly rbcFilterStore: RBCFilterStore;
    readonly wordsAndPhrasesStore: WordsPhrasesStore;
    private readonly agentService = new AgentService();

    @observable startDate?: moment.Moment | undefined;
    @observable endDate?: moment.Moment | undefined;
    @observable dateReferenceOption: DateReferenceOption;
    @computed get dateReference() {
        return this.dateReferenceOption;
    }
    @observable dateRangeLabel?: DateRangeLabel | undefined;

    @observable minCallDuration?: number = 0;
    @observable maxCallDuration?: number = 0;
    @computed get callDuration() {
        return [this.minCallDuration ?? 0, this.maxCallDuration ?? 0] as [
            number,
            number,
        ];
    }

    @observable evaluationTypes: EvaluationType[] = [];

    @observable hierarchyIds: string[] = [];
    @observable selectedCategories: string[] = [];
    @observable selectedSubcategories: string[] = [];

    @observable agentId?: string;
    @observable agentIds: string[] = [];
    @observable detailedAgents: Agentv2[] = [];

    @observable mediaTypes: MediaType[] = [];
    @observable callDirections: CallDirection[] = [];
    @observable clientCallId?: string;

    @observable meta1?: string;
    @observable meta2?: string;
    @observable meta3?: string;
    @observable meta4?: string;
    @observable meta5?: string;

    @observable beginningSentiment?: Sentiment;
    @observable endingSentiment?: Sentiment;

    @observable eddyEffectStatus?: boolean;

    @observable safetyEvent?: SafetyEvent;
    @observable hipaaCompliance?: HipaaCompliance;
    @observable adverseEvent?: boolean;

    @observable contactTypes: string[] = [];

    @observable topics: SimpleTopicCluster[] = [];
    @observable containsTopics: boolean = true;
    @computed get containsTopicsString() {
        if (this.containsTopics) return "is one of";
        else return "is not one of";
    }

    @observable extendedMetadata?: Record<string, string>;

    @observable wordsAndPhrasesSearchString?: string;

    @computed get wordsAndPhrasesItems() {
        return this.wordsAndPhrasesStore.wordsPhrasesItems;
    }

    @computed get wordsAndPhrasesIsInputStandard() {
        return this.wordsAndPhrasesStore.isInputStandard;
    }

    @computed get rbcFilterItems() {
        return this.rbcFilterStore.RBCFilterItems;
    }

    @observable dataLabels: DataLabel[] = [];
    @observable containsDataLabels?: boolean;
    @computed get containsDataLabelsString() {
        if (this.containsDataLabels) return "is labeled";
        return "is not labeled";
    }

    /**
     * Object for storing filter state associated to a key
     */
    @observable savedFilterStates: Record<string, ApplicationFilters> = {};

    @observable favoriteConversations?: boolean | undefined;

    constructor(rootStore: IRootStore, uniqueStoreName?: string) {
        super(uniqueStoreName ?? "Application Filters Instance Store");
        makeObservable(this);

        this.initializeDatePickerStore();
        this.copyFromDatePickerStore();
        this.rbcFilterStore = new RBCFilterStore(rootStore);
        this.wordsAndPhrasesStore = new WordsPhrasesStore(rootStore);
        this.wordsAndPhrasesStore.onSearchQueryChange = (searchPhrase) =>
            this.setWordsAndPhrasesSearchString(searchPhrase);
    }

    protected onFilterChange(): void {}

    private initializeDatePickerStore() {
        this.datePickerStore = new DatePickerComponentStore();
        this.datePickerStore.setBeginDate(
            moment().subtract(7, "days").startOf("day"),
        );
        this.datePickerStore.setEndDate(moment());
        this.datePickerStore.setReferenceOption(
            DateReferenceOption.InteractionDate,
        );
    }

    @action
    private copyFromDatePickerStore() {
        this.startDate = this.datePickerStore.beginDate;
        this.endDate = this.datePickerStore.endDate;
        this.dateReferenceOption = this.datePickerStore.referenceOption;
        this.dateRangeLabel = this.datePickerStore.dateRangePresetLabel;
    }

    @action
    public removeDateRange() {
        // this.datePickerStore = undefined;
        this.startDate = undefined;
        this.endDate = undefined;
        console.log("Removed date range", this);
    }

    @action
    setStartDate(startDate?: string | moment.Moment) {
        const date = moment(startDate);
        this.startDate = date;
        if (startDate) this.datePickerStore.setBeginDate(date);
        this.onFilterChange();
    }

    @action
    setEndDate(endDate?: string | moment.Moment) {
        const date = moment(endDate);
        this.endDate = date;
        if (endDate) this.datePickerStore.setEndDate(date);
        this.onFilterChange();
    }

    @action
    setDatePair(
        startDate?: string | moment.Moment,
        endDate?: string | moment.Moment,
    ) {
        this.setStartDate(startDate);
        this.setEndDate(endDate);
        this.onFilterChange();
    }

    /**
     * Sets the date picker stores reference type.
     * @default DateReferenceOption.InteractionDate
     * @param dateReferenceOption
     */
    @action
    setDateReferenceOption(dateReferenceOption?: DateReferenceOption) {
        dateReferenceOption ??= DateReferenceOption.InteractionDate;
        this.dateReferenceOption = dateReferenceOption;
        if (this.datePickerStore)
            this.datePickerStore.setReferenceOption(dateReferenceOption);
        this.onFilterChange();
    }

    @action
    setDateRangeLabel(dateRangeLabel?: DateRangeLabel) {
        dateRangeLabel ??= "";
        this.dateRangeLabel = dateRangeLabel;
        if (this.datePickerStore)
            this.datePickerStore.setDateRangePreset(dateRangeLabel);
        if (dateRangeLabel !== "") {
            const newDateRange = getDateFromDateRangeLabel(dateRangeLabel);
            this.setStartDate(newDateRange[0]);
            this.setEndDate(newDateRange[1]);
        }
        this.onFilterChange();
    }

    @action
    setMinCallDuration(duration?: number) {
        this.minCallDuration = duration;
        this.onFilterChange();
    }

    @action
    setMaxCallDuration(duration?: number) {
        this.maxCallDuration = duration;
        this.onFilterChange();
    }

    @action
    setCallDuration(duration?: [number, number] | number[]) {
        this.setMinCallDuration(duration?.[0]);
        this.setMaxCallDuration(duration?.[1]);
        this.onFilterChange();
    }

    @action
    setHierarchyIds(hierarchyIds: string[]) {
        this.hierarchyIds = hierarchyIds;
        this.onFilterChange();
    }

    @action
    setSelectedCategories(categories: string[], subcategories: string[]) {
        this.selectedCategories = categories;
        this.selectedSubcategories = subcategories;
    }

    @action
    setAgentId(agentId?: string) {
        this.agentId = agentId;
        this.onFilterChange();
    }

    @action
    setAgentIds(agents?: string[]) {
        this.agentIds = agents ?? [];
        this.onFilterChange();
    }

    @action
    setDetailedAgents(agents: Agentv2[]) {
        this.detailedAgents = agents;
    }

    @action
    setMediaTypes(mediaTypes: MediaType[]) {
        this.mediaTypes = mediaTypes;
        this.onFilterChange();
    }

    @action
    toggleMediaType(mediaType: MediaType) {
        if (this.mediaTypes.includes(mediaType))
            this.mediaTypes = this.mediaTypes.filter(
                (type) => type !== mediaType,
            );
        else this.mediaTypes.push(mediaType);
        this.onFilterChange();
    }

    @action
    setCallDirections(callDirections: CallDirection[]) {
        this.callDirections = callDirections;
        this.onFilterChange();
    }

    @action
    toggleCallDirection(callDirection: CallDirection) {
        if (this.callDirections.includes(callDirection))
            this.callDirections = this.callDirections.filter(
                (direction) => direction !== callDirection,
            );
        else this.callDirections.push(callDirection);
        this.onFilterChange();
    }

    @action
    setClientCallId(clientCallId?: string) {
        this.clientCallId = clientCallId;
        this.onFilterChange();
    }

    @action
    setMeta1(meta1?: string) {
        this.meta1 = meta1;
        this.onFilterChange();
    }

    @action
    setMeta2(meta2?: string) {
        this.meta2 = meta2;
        this.onFilterChange();
    }
    @action
    setMeta3(meta3?: string) {
        this.meta3 = meta3;
        this.onFilterChange();
    }

    @action
    setMeta4(meta4?: string) {
        this.meta4 = meta4;
        this.onFilterChange();
    }

    @action
    setMeta5(meta5?: string) {
        this.meta5 = meta5;
        this.onFilterChange();
    }

    @action
    setExtendedMetadata(extendedMetadata?: Record<string, string>) {
        this.extendedMetadata = extendedMetadata;
        this.onFilterChange();
    }

    @action
    setExtendedMetadataPair(key: string, value: string) {
        if (!this.extendedMetadata) this.setExtendedMetadata({});
        this.extendedMetadata![key] = value;
        this.onFilterChange();
    }

    @action
    setBeginningSentiment(sentiment?: Sentiment) {
        this.beginningSentiment = sentiment;
        this.onFilterChange();
    }

    @action
    setEndingSentiment(sentiment?: Sentiment) {
        this.endingSentiment = sentiment;
        this.onFilterChange();
    }

    @action
    setEddyEffectStatus(status?: boolean) {
        this.eddyEffectStatus = status;
        this.onFilterChange();
    }

    @action
    setSafetyEvent(safetyEvent?: SafetyEvent) {
        this.safetyEvent = safetyEvent;
        this.onFilterChange();
    }

    @action
    setHipaaCompliance(hipaaCompliance?: HipaaCompliance) {
        this.hipaaCompliance = hipaaCompliance;
        this.onFilterChange();
    }

    @action
    setAdverseEvent(adverseEvent?: boolean) {
        this.adverseEvent = adverseEvent;
        this.onFilterChange();
    }

    @action
    setContactTypes(contactTypes: string[] | ContactTypeOption[]) {
        if (contactTypes.length === 0) this.contactTypes = [];
        else {
            const singleContactType = contactTypes[0];
            if (typeof singleContactType === "string")
                this.contactTypes = contactTypes as string[];
            else
                this.contactTypes = (contactTypes as ContactTypeOption[]).map(
                    (i) => i.value,
                );
        }

        this.onFilterChange();
    }

    @action
    setTopics(topics: SimpleTopicCluster[]) {
        this.topics = topics;
        this.onFilterChange();
    }

    @action
    setContainsTopics(contains: boolean) {
        this.containsTopics = contains;
        this.onFilterChange();
    }

    @action
    setWordsAndPhrasesSearchString(search?: string) {
        this.wordsAndPhrasesSearchString = search;
        this.onFilterChange();
    }

    @action
    setWordsAndPhrasesItems(wordsAndPhrasesItems: IWordsPhrasesModel[]) {
        this.wordsAndPhrasesStore.setWordsPhrasesItems(wordsAndPhrasesItems);
        this.onFilterChange();
    }

    @action
    setWordsAndPhrasesIsInputStandard(isInputStandard?: boolean) {
        if (this.wordsAndPhrasesStore.isInputStandard !== isInputStandard)
            this.wordsAndPhrasesStore.toggleStandardInput();
        this.onFilterChange();
    }

    @action
    setRBCFilterItems(rbcFilterItems: IRBCFilter[]) {
        this.rbcFilterStore.setRBCFilterItems(rbcFilterItems);
        this.onFilterChange();
    }

    @action
    setFavoriteConversations(favoriteConversations?: boolean) {
        this.favoriteConversations = favoriteConversations;
        this.onFilterChange();
    }

    @action
    setContainsDataLabels(containsDataLabels?: boolean) {
        this.containsDataLabels = containsDataLabels;
        this.onFilterChange();
    }

    @action
    setDataLabels(dataLabels: DataLabel[]) {
        this.dataLabels = dataLabels;
        this.onFilterChange();
    }

    /**
     * Facade for calling rbcFilterStore.removeClassifierById but also ensures
     * that this.onFilterChange is invoked afterwards.
     * @param classifierId
     */
    @action
    removeClassifierById(classifierId: string) {
        const result = this.rbcFilterStore.removeClassifierById(classifierId);
        this.onFilterChange();
        return result;
    }

    @action
    setEvaluationTypes(evaluationTypes: EvaluationType[]) {
        this.evaluationTypes = evaluationTypes;
        this.onFilterChange();
    }

    /**
     * Directly indexes this value with `key` setting it to `value`
     * @param key an application filter key
     * @param value
     */
    @action
    setByKey<T extends keyof ApplicationFilters>(
        key: T | `extendedMetadata.${string}`,
        value: this[T],
    ) {
        _.set(this, key, value);
        this.onFilterChange();
    }

    @action
    resetByKey<T extends keyof ApplicationFilters>(
        key: T | `extendedMetadata.${string}`,
        resetValue: unknown = undefined,
    ) {
        if (key === "rbcFilterItems")
            this.setRBCFilterItems(initRBCFilterItems);
        else if (key === "wordsAndPhrasesSearchString")
            this.setWordsAndPhrasesItems(initWordsPhrasesItems);
        else if (key === "dateReferenceOption")
            this.setDateReferenceOption(DateReferenceOption.InteractionDate);
        else if (Array.isArray(_.get(this, key))) _.set(this, key, []);
        else _.set(this, key, resetValue);
        this.onFilterChange();
    }

    /**
     * Given an evaluation type controls whether its present in the evaluation
     * types array. Takes into account that not evaluated cannot be selected
     * alongside other evaluated options.
     * @param evaluationType
     * @returns
     */
    @action
    toggleEvaluationType(evaluationType: EvaluationType) {
        if (evaluationType === EvaluationType.NotEvaluated) {
            if (this.evaluationTypes.includes(EvaluationType.NotEvaluated))
                this.evaluationTypes = [];
            else this.evaluationTypes = [EvaluationType.NotEvaluated];
            return;
        }

        if (
            evaluationType === EvaluationType.HumanEvaluated ||
            evaluationType === EvaluationType.AIEvaluated
        ) {
            if (this.evaluationTypes.includes(EvaluationType.NotEvaluated))
                this.evaluationTypes.splice(
                    this.evaluationTypes.indexOf(EvaluationType.NotEvaluated),
                    1,
                );

            const index = this.evaluationTypes.indexOf(evaluationType);
            if (index >= 0) this.evaluationTypes.splice(index, 1);
            else this.evaluationTypes.push(evaluationType);
        }
        this.onFilterChange();
    }

    @action
    toggleFavoriteConversationsFilter() {
        this.favoriteConversations = this.favoriteConversations
            ? undefined
            : true;
    }

    @computed get hasChangedSinceLastUpdate() {
        return this.hasFilterStateChangedSince("Update");
    }

    /**
     * Given a key to a saved filter state, return a boolean
     * indicating whether the state at the given key is different
     * than the current state
     * @param referenceStateKey
     * @returns
     */
    public hasFilterStateChangedSince(referenceStateKey: string) {
        const referenceState = this.savedFilterStates[referenceStateKey];
        if (!referenceState) return false;

        return this.areFiltersDifferent(_.cloneDeep(referenceState));
    }

    @action
    public saveCurrentFilterState(key: string) {
        this.savedFilterStates[key] = _.cloneDeep(this.toFilterObject());
    }

    public async validateWordsAndPhrases() {
        if (
            ApplicationFiltersInstanceStore.isStringUndefinedOrEmpty(
                this.wordsAndPhrasesSearchString,
            )
        )
            return true;

        return this.setupAsyncTask(
            ApplicationFiltersInstanceStore.Tasks.VALIDATE_WORDS_AND_PHRASES,
            async () => {
                await this.wordsAndPhrasesStore.validateWordsPhrasesAdvString();
                return this.wordsAndPhrasesStore.isValidWordsPhrasesString;
            },
        );
    }

    @action
    public resetFilters() {
        this.initializeDatePickerStore();
        this.copyFromDatePickerStore();
        this.setCallDuration([0, 0]);
        this.setHierarchyIds([]);
        this.setSelectedCategories([], []);
        this.setEvaluationTypes([]);
        this.setMediaTypes([]);
        this.setCallDirections([]);
        this.setClientCallId(undefined);
        this.setEddyEffectStatus(undefined);
        this.setHipaaCompliance(undefined);
        this.setBeginningSentiment(undefined);
        this.setEndingSentiment(undefined);
        this.setSafetyEvent(undefined);
        this.setAdverseEvent(undefined);
        this.setAgentId(undefined);
        this.setAgentIds([]);
        this.detailedAgents = [];
        this.setContainsTopics(true);
        this.setTopics([]);
        this.setRBCFilterItems(initRBCFilterItems);
        this.setWordsAndPhrasesItems(initWordsPhrasesItems);
        this.setWordsAndPhrasesIsInputStandard(true);
        this.setMeta1(undefined);
        this.setMeta2(undefined);
        this.setMeta3(undefined);
        this.setMeta4(undefined);
        this.setMeta5(undefined);
        this.setExtendedMetadata(undefined);
        this.setContactTypes([]);
        this.setFavoriteConversations(undefined);
        this.setContainsDataLabels(false);
        this.setDataLabels([]);
    }

    @action
    applyExternalFilters(filters: ApplicationFilters) {
        if (!filters.startDate && !filters.endDate) {
            this.removeDateRange();
        } else {
            this.setStartDate(filters.startDate);
            this.setEndDate(filters.endDate);
        }
        this.setDateReferenceOption(filters.dateReferenceOption);
        this.setDateRangeLabel(filters.dateRangeLabel ?? "");
        this.setMinCallDuration(filters.minCallDuration);
        this.setMaxCallDuration(filters.maxCallDuration);
        this.setEvaluationTypes(filters.evaluationTypes ?? []);
        this.setHierarchyIds(filters.hierarchyIds ?? []);
        this.setSelectedCategories(
            filters.selectedCategories ?? [],
            filters.selectedSubcategories ?? [],
        );
        this.setMediaTypes(filters.mediaTypes ?? []);
        this.setCallDirections(filters.callDirections ?? []);
        this.setClientCallId(filters.clientCallId);
        this.setMeta1(filters.meta1);
        this.setMeta2(filters.meta2);
        this.setMeta3(filters.meta3);
        this.setMeta4(filters.meta4);
        this.setMeta5(filters.meta5);
        this.setExtendedMetadata(filters.extendedMetadata);
        this.setBeginningSentiment(filters.beginningSentiment);
        this.setEndingSentiment(filters.endingSentiment);
        this.setEddyEffectStatus(filters.eddyEffectStatus);
        this.setSafetyEvent(filters.safetyEvent);
        this.setHipaaCompliance(filters.hipaaCompliance);
        this.setAdverseEvent(filters.adverseEvent);
        this.setTopics(filters.topics ?? []);
        this.setContainsTopics(filters.containsTopics ?? true);
        this.setWordsAndPhrasesSearchString(
            filters.wordsAndPhrasesSearchString,
        );
        this.setWordsAndPhrasesItems(
            filters.wordsAndPhrasesItems ?? initWordsPhrasesItems,
        );
        this.setWordsAndPhrasesIsInputStandard(
            filters.wordsAndPhrasesIsInputStandard ?? true,
        );
        this.setRBCFilterItems(filters.rbcFilterItems ?? initRBCFilterItems);
        this.setContactTypes(
            (filters.contactTypes ?? []).map((i) => ({ label: i, value: i })),
        );
        this.setFavoriteConversations(filters.favoriteConversations);
        this.setContainsDataLabels(filters.containsDataLabels ?? false);
        this.setDataLabels(filters.dataLabels ?? []);

        this.setAgentIds(filters.agentIds ?? []);

        // If agentIds are present in the filter and they are different from the ids of the current detailed agents
        // then fetch the detailed agents
        if (filters.agentIds) {
            if (
                !_.isEqual(
                    filters.agentIds.sort(),
                    this.detailedAgents.map((agent) => agent.id).sort(),
                )
            ) {
                this.agentService
                    .getAgentsByIds(filters.agentIds)
                    .then((agents) => {
                        this.setDetailedAgents(agents);
                    });
            }
        } else {
            this.detailedAgents = [];
        }
    }

    /**
     * @returns A non-observed object with all filterables
     */
    toFilterObject(): ApplicationFilters {
        return toJS({
            startDate: this.startDate,
            endDate: this.endDate,
            dateReference: this.dateReference,
            dateReferenceOption: this.dateReferenceOption,
            dateRangeLabel: this.dateRangeLabel,
            minCallDuration: this.minCallDuration,
            maxCallDuration: this.maxCallDuration,
            evaluationTypes: this.evaluationTypes,
            hierarchyIds: this.hierarchyIds,
            selectedCategories: this.selectedCategories,
            selectedSubcategories: this.selectedSubcategories,
            agentId: this.agentId,
            agentIds: this.agentIds,
            mediaTypes: this.mediaTypes,
            callDirections: this.callDirections,
            clientCallId: this.clientCallId,
            meta1: this.meta1,
            meta2: this.meta2,
            meta3: this.meta3,
            meta4: this.meta4,
            meta5: this.meta5,
            extendedMetadata: this.extendedMetadata,
            beginningSentiment: this.beginningSentiment,
            endingSentiment: this.endingSentiment,
            eddyEffectStatus: this.eddyEffectStatus,
            safetyEvent: this.safetyEvent,
            hipaaCompliance: this.hipaaCompliance,
            adverseEvent: this.adverseEvent,
            topics: this.topics,
            containsTopics: this.containsTopics,
            wordsAndPhrasesSearchString: this.wordsAndPhrasesSearchString,
            wordsAndPhrasesItems: this.wordsAndPhrasesItems,
            wordsAndPhrasesIsInputStandard: this.wordsAndPhrasesIsInputStandard,
            rbcFilterItems: this.rbcFilterItems,
            contactTypes: this.contactTypes,
            favoriteConversations: this.favoriteConversations,
            containsDataLabels: this.containsDataLabels,
            dataLabels: this.dataLabels,
        });
    }

    /**
     * Uses toFilterObject but applies some processing to meta 1-5 and extended metadata.
     * This should still return a valid ApplicationFilters object.
     */
    toRequestObject(): ApplicationFilters {
        const filters = this.toFilterObject();

        const dates = toIsoByDateReference(
            moment(filters.startDate),
            moment(filters.endDate),
            filters.dateReferenceOption,
        );

        filters.startDate = dates.beginDate;
        filters.endDate = dates.endDate;

        filters.meta1?.replace(/'/g, "''");
        filters.meta2?.replace(/'/g, "''");
        filters.meta3?.replace(/'/g, "''");
        filters.meta4?.replace(/'/g, "''");
        filters.meta5?.replace(/'/g, "''");

        if (filters.extendedMetadata) {
            for (const [key, value] of Object.entries(
                filters.extendedMetadata,
            )) {
                if (!value) continue;
                filters.extendedMetadata[key] = value.replace(/'/g, "''");
            }
        }

        filters.rbcFilterItems =
            filters.rbcFilterItems?.filter(
                (item) => item.classifierIds.length > 0,
            ) ?? [];

        if (filters.minCallDuration === 0) filters.minCallDuration = undefined;
        if (filters.maxCallDuration === 0) filters.maxCallDuration = undefined;

        return filters;
    }

    public areFiltersDifferent(filters: ApplicationFilters) {
        return !_.isEqual(_.cloneDeep(this.toFilterObject()), filters);
    }

    protected static isStringUndefinedOrEmpty(value?: string) {
        return value === undefined || value === "";
    }

    protected static isUndefinedOrNull<T>(value?: T | null) {
        return value === undefined || value === null;
    }

    get appliedFilterCount() {
        return ApplicationFiltersInstanceStore.getAppliedFilterCount(this);
    }

    static getAppliedFilterCount(filters: ApplicationFilters) {
        let count = 0;

        if (
            (!!filters.minCallDuration && filters.minCallDuration !== 0) ||
            (!!filters.maxCallDuration && filters.maxCallDuration !== 0)
        )
            count++;
        if (
            !this.isUndefinedOrNull(filters.mediaTypes) &&
            filters.mediaTypes.length > 0
        )
            count++;
        if (
            !this.isUndefinedOrNull(filters.callDirections) &&
            filters.callDirections.length > 0
        )
            count++;
        if (!this.isStringUndefinedOrEmpty(filters.clientCallId)) count++;
        if (
            !this.isUndefinedOrNull(filters.hierarchyIds) &&
            filters.hierarchyIds.length > 0
        )
            count++;
        if (!this.isUndefinedOrNull(filters.eddyEffectStatus)) count++;
        if (!this.isUndefinedOrNull(filters.hipaaCompliance)) count++;
        if (!this.isUndefinedOrNull(filters.beginningSentiment)) count++;
        if (!this.isUndefinedOrNull(filters.endingSentiment)) count++;
        if (!this.isUndefinedOrNull(filters.safetyEvent)) count++;
        if (!this.isUndefinedOrNull(filters.adverseEvent)) count++;
        if (
            !this.isUndefinedOrNull(filters?.agentIds) &&
            (filters?.agentIds ?? []).length > 0
        )
            count++;
        if (
            !this.isUndefinedOrNull(filters.topics) &&
            filters.topics.length > 0
        )
            count++;
        if (
            !this.isUndefinedOrNull(filters.contactTypes) &&
            filters.contactTypes.length > 0
        )
            count++;
        if (
            !this.isUndefinedOrNull(filters.rbcFilterItems) &&
            filters.rbcFilterItems.length > 0 &&
            filters.rbcFilterItems.some(
                (value) => value.classifierIds.length > 0,
            )
        )
            count++;
        if (!this.isStringUndefinedOrEmpty(filters.wordsAndPhrasesSearchString))
            count++;
        if (
            !this.isUndefinedOrNull(filters.evaluationTypes) &&
            filters.evaluationTypes.length > 0
        )
            count++;

        if (!this.isStringUndefinedOrEmpty(filters.meta1)) count++;
        if (!this.isStringUndefinedOrEmpty(filters.meta2)) count++;
        if (!this.isStringUndefinedOrEmpty(filters.meta3)) count++;
        if (!this.isStringUndefinedOrEmpty(filters.meta4)) count++;
        if (!this.isStringUndefinedOrEmpty(filters.meta5)) count++;

        if (!!filters.extendedMetadata)
            for (const value of Object.values(filters.extendedMetadata)) {
                if (
                    !this.isUndefinedOrNull(value) &&
                    !this.isStringUndefinedOrEmpty(value)
                )
                    count++;
            }

        if (filters.favoriteConversations) count++;
        if (filters.dataLabels.length > 0) count++;

        return count;
    }
}
