import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import React, { useEffect, useMemo, useRef } from "react";
import Chart from "react-google-charts";
import { GoogleChartWrapper, GoogleViz } from "react-google-charts/dist/types";
import { getVAxisWidth } from "utils/helpers";
import theme from "../../../Theme/AppTheme";
import { defaultColorScheme } from "./GraphColorSchemes";

const chartName = "AcxVerticalBar";

type GoogleDataFormat = any[][];

type AxesScaleTypes = "log" | "mirrorLog" | null;

// see: https://developers.google.com/chart/interactive/docs/customizing_axes#number-formats
type NumberFormatPreset =
    | "none"
    | "decimal"
    | "scientific"
    | "currency"
    | "percent"
    | "short"
    | "long";

interface OwnProps {
    data?: GoogleDataFormat;
    previousData?: GoogleDataFormat;

    colorScheme?: string[]; // not actually used since the utility reportDataToGoogleFormat styles each bar based on
    // theme colors

    legendPosition: "none" | "bottom" | "in" | "right" | "top";
    legendAlignment?: "start" | "center" | "end";
    showGrid?: boolean;
    yTitle?: string;
    xTitle?: string;
    fontSize?: string;
    fontBold?: boolean;
    fontItalic?: boolean;
    fontColor?: string;
    dataOpacity?: number;
    hAxisFontSize?: string;
    vAxisFontSize?: string;
    vAxisFormat?: NumberFormatPreset;
    vAxisTitleStyle?: Record<string, unknown>;
    vAxisTextStyle?: Record<string, unknown>;

    onSelect?: (
        series: string | null,
        x: string | number | null,
        y: string | number | null | any,
        chartId?: string,
        metadata?: any[],
    ) => void;

    chartAreaLeftOffset?: number | string;
    chartAreaTopOffset?: number | string;
    chartAreaHeight?: number | string;
    chartAreaWidth?: number | string;

    showDemoData?: boolean;
    asWidget?: boolean;

    slantedText?: boolean;
    slantedTextAngle?: number;

    enableZoom?: boolean;
    stacked?: boolean;
    verticalScaleType?: AxesScaleTypes;
    horizontalScaleType?: AxesScaleTypes;

    onChartReady?: (
        chartWrapper: GoogleChartWrapper,
        google: GoogleViz,
    ) => void;

    metadata?: Map<string, any[]> | null;
    chartId?: string;
    chartHeight?: string;

    usesHtmlTooltips?: boolean;
}

type Props = OwnProps;

const AcxVerticalBarGraph: React.FC<Props> = (props) => {
    const wrapper = useRef<null | any>();
    useEffect(() => {
        return () => {
            wrapper.current?.getChart()?.clearChart();
        };
    }, []);

    const data = Boolean(props.showDemoData) ? fake_data : props.data;

    const showLegend =
        (data?.[0].filter((d) => !Boolean(d.role)).length ?? 0) > 2;

    const options = useMemo(() => {
        const res = {
            fontName: "Inter",
            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontName: "Inter",
                    fontSize: props.fontSize ?? 18,
                    bold: props.fontBold ?? true,
                    italic: props.fontItalic ?? true,
                    // The color of the text.
                    color: props.fontColor ?? "#000",
                    // The color of the text outline.
                    auraColor: "transparent",
                    // The transparency of the text.
                    // opacity: 0.6
                },
            },
            dataOpacity: props.dataOpacity ?? 0.65,
            isStacked: Boolean(props.stacked),
            animation: {
                startup: true,
                duration: 500,
                easing: "out",
            },
            interpolateNulls: true,
            colors:
                props.colorScheme && props.colorScheme.length
                    ? props.colorScheme
                    : defaultColorScheme,
            tooltip: {
                textStyle: { fontName: "Inter" },
                isHtml: !!props.usesHtmlTooltips,
            },
            legend: {
                position: showLegend ? props.legendPosition : "none",
                alignment: props.legendAlignment
                    ? props.legendAlignment
                    : "start",
                textStyle: {
                    fontName: "Inter",
                },
                maxLines: 5,
            },
            chart: {},
            chartArea: {
                left: props.vAxisFontSize
                    ? getVAxisWidth(props.vAxisFontSize)
                    : props.chartAreaLeftOffset ??
                      (props.asWidget ? "8%" : "4%"),
                top: props.chartAreaTopOffset ?? (props.asWidget ? "7%" : "5%"),
                width: props.chartAreaWidth ?? (props.asWidget ? "88%" : "90%"),
                height: props.chartAreaHeight
                    ? `${props.chartAreaHeight.toString().replace("%", "")}%`
                    : props.asWidget
                    ? "77%"
                    : "90%",
            },
            hAxis: {
                // textPosition: props.asWidget ? undefined : "in",
                scaleType: null as AxesScaleTypes,
                // title: props.xTitle,
                slantedText:
                    props.slantedText !== undefined
                        ? props.slantedText
                        : props.asWidget
                        ? undefined
                        : true,
                slantedTextAngle:
                    props.slantedTextAngle ?? (props.asWidget ? undefined : 15),
                maxAlternation: 3,
                showTextEvery: 1,
                titleTextStyle: {
                    color: theme.palette.grey[300],
                    auraColor: "none",
                    fontSize: props.asWidget ? 13 : 16,
                    fontName: "Inter Bold",
                },
                textStyle: {
                    color: theme.palette.gray[400],
                    auraColor: "none",
                    fontSize: props.asWidget ? 8 : props.hAxisFontSize ?? 11,
                    fontName: "Inter Bold",
                },
                minorGridLines: {
                    count: 0,
                },
                gridLines: {},
                // viewWindowMode:"pretty",
            },
            vAxis: {
                scaleType: null as AxesScaleTypes,
                title: props.yTitle,
                titleTextStyle: {
                    color: theme.palette.gray[400],
                    auraColor: "none",
                    italic: false,
                    fontSize: props.asWidget ? 13 : 16,
                    fontName: "Inter Bold",
                    ...props.vAxisTitleStyle,
                },
                textStyle: {
                    color: theme.palette.gray[400],
                    auraColor: "none",
                    fontSize: props.asWidget ? 10 : props.vAxisFontSize ?? 13,
                    fontName: "Inter Bold",
                    ...props.vAxisTextStyle,
                },
                minorGridLines: {
                    count: 0,
                },
                gridlines: {
                    color: props.showGrid ? "E7E7E7" : "none",
                },
                minValue: 0,
                format: props.vAxisFormat,
            },

            explorer: undefined as any | undefined,

            enableInteractivity: true,
        };

        res.vAxis.scaleType = props.verticalScaleType ?? null;
        res.hAxis.scaleType = props.horizontalScaleType ?? null;

        return res;
    }, [
        props.asWidget,
        props.chartAreaHeight,
        props.chartAreaLeftOffset,
        props.chartAreaTopOffset,
        props.chartAreaWidth,
        props.colorScheme,
        props.dataOpacity,
        props.fontBold,
        props.fontColor,
        props.fontItalic,
        props.fontSize,
        props.hAxisFontSize,
        props.horizontalScaleType,
        props.legendAlignment,
        props.legendPosition,
        props.showGrid,
        props.slantedText,
        props.slantedTextAngle,
        props.stacked,
        props.usesHtmlTooltips,
        props.vAxisFontSize,
        props.verticalScaleType,
        props.yTitle,
        props.vAxisFormat,
        props.vAxisTitleStyle,
        props.vAxisTextStyle,
        showLegend,
    ]);

    // chartEvents callbacks are memoized and can only access props from initial render
    // this ref stores an updated data prop for use in the chartEvent select callback
    const dataRef = useRef({ data: props.data });

    useEffect(() => {
        dataRef.current = { data: props.data };
    }, [props.data]);

    return (
        <>
            {Boolean(data) && (
                <Chart
                    width={`100%`}
                    height={
                        props.chartHeight
                            ? props.chartHeight
                            : props.asWidget
                            ? `97%`
                            : "100%"
                    }
                    chartType="ColumnChart"
                    loader={
                        <AcxLoadingIndicator
                            color="secondary"
                            alternate="PuffLoader"
                            size={96}
                            style={
                                props.chartHeight
                                    ? { height: props.chartHeight }
                                    : {}
                            }
                        />
                    }
                    data={Boolean(props.previousData) ? undefined : data}
                    diffdata={
                        Boolean(props.previousData)
                            ? {
                                  old: props.previousData,
                                  new: props.data,
                              }
                            : undefined
                    }
                    options={options}
                    chartEvents={[
                        {
                            eventName: "select",
                            callback: ({ chartWrapper }) => {
                                const chart = chartWrapper.getChart();
                                const selection = chart.getSelection();

                                if (selection.length === 1) {
                                    const [selectedItem] = selection;
                                    const dataTable =
                                        chartWrapper.getDataTable();
                                    const { row, column } = selectedItem;

                                    if (row !== null && column !== null) {
                                        const rowLabel =
                                            dataRef.current.data?.[
                                                row + 1
                                            ]?.[0];

                                        props.onSelect?.(
                                            dataTable?.getColumnLabel(column) ??
                                                null,
                                            rowLabel,
                                            dataTable?.getValue(row, column) ??
                                                null,
                                            // row,
                                            props.chartId,
                                            props.metadata?.get(rowLabel),
                                        );
                                    } else if (row !== null) {
                                        const rowLabel =
                                            dataRef.current.data?.[
                                                row + 1
                                            ]?.[0];
                                        console.log(
                                            `${chartName} selection : ${JSON.stringify(
                                                {
                                                    row,
                                                    column,
                                                    X: rowLabel,
                                                    rowProps:
                                                        dataTable?.getRowProperties(
                                                            row,
                                                        ),
                                                },
                                            )}`,
                                        );
                                        props.onSelect?.(
                                            null,
                                            rowLabel,
                                            null,
                                            // row,
                                            props.chartId,
                                            props.metadata?.get(rowLabel),
                                        );
                                    } else if (column !== null) {
                                        console.log(
                                            `${chartName} selection : ${JSON.stringify(
                                                {
                                                    row,
                                                    column,
                                                    series: dataTable?.getColumnLabel(
                                                        column,
                                                    ),
                                                    columnProps:
                                                        dataTable?.getColumnProperties(
                                                            column,
                                                        ),
                                                },
                                            )}`,
                                        );
                                        props.onSelect?.(
                                            dataTable?.getColumnLabel(column) ??
                                                null,
                                            null,
                                            // null,
                                            null,
                                        );
                                    }
                                }
                            },
                        },
                    ]}
                    getChartWrapper={(w, g) => {
                        wrapper.current = w;
                        props.onChartReady?.(w, g);
                    }}
                />
            )}
        </>
    );
};

const fake_data = [
    ["Tags", "Count"],
    ["Help With Bill", 8.94],
    ["Brand Detractor", 10.49],
    ["Program Value", 19.3],
    ["Brand Value", 21.45],
];

export default AcxVerticalBarGraph;
