import { observer } from "mobx-react";
import React, { useEffect } from "react";
import {
    EvalLicensedModule,
    EvalQuestion,
} from "components/Evaluation/Stores/EvalStore";
import Question, {
    QuestionConditionalGroupType,
    QuestionDependencyCondition,
    QuestionDependencyQuestionAnswerCondition,
    QuestionDependencyQuestionAnswerOperator,
    QuestionDependencyType,
} from "models/Question";
import { Answer } from "models/Answer";
import { ThemeColors } from "Theme/AppTheme";
import { AnswerTypeNames } from "models/AnswerType";
import hexToRGB from "utils/hexToRGB";
import WFRScaledResponseAnswer from "./WFRScaledResponseAnswer";
import WFRStarRatingAnswer from "./WFRStarRatingAnswer";
import WFRTagOrFillInResponseAnswer from "./WFRTagOrFillResponseAnswer";
import { useStore } from "utils/useStore";
import { WorkflowReviewStore } from "components/WorkflowReview/Stores/WorkflowReviewStore";
import { WorkflowConditionType } from "components/Admin/Organizations/OrganizationDetail/OrganizationWorkflows/Types/WorkflowModels";
import { isNotNull } from "utils/helpers";

interface WFRQuestionAnswerListProps {
    module?: EvalLicensedModule;
    disputeHasStarted: boolean;
    standalone?: boolean;
    isEditable?: boolean;
    isNoteEditable?: boolean;
    hideHiddenAnswers?: boolean;
    isWorkflowModule?: boolean;
}

function isConditionMet(
    condition: QuestionDependencyCondition,
    answers: Answer[],
    evaluationModuleId: string,
): boolean {
    switch (condition.type) {
        case QuestionDependencyType.QuestionAnswer:
            const value = condition.value;
            const answer = answers.find(
                (a) =>
                    a.questionId === condition.dependsOnQuestionId &&
                    a.evaluationModuleId === evaluationModuleId,
            );

            if (!answer) {
                return false;
            }

            switch (condition.op) {
                case QuestionDependencyQuestionAnswerOperator.Contains:
                    return answer.activeAnswerTags.some(
                        (t) => t.tag?.value === value,
                    );
                case QuestionDependencyQuestionAnswerOperator.IsAnswered:
                    return !!answer.activeAnswerTags.length;
            }

            break;
        case QuestionDependencyType.ConditionalGroup:
            switch (condition.conditionalGroupType) {
                case QuestionConditionalGroupType.And:
                    return condition.conditions.every((c) =>
                        isConditionMet(c, answers, evaluationModuleId),
                    );
                case QuestionConditionalGroupType.Or:
                    return condition.conditions.some((c) =>
                        isConditionMet(c, answers, evaluationModuleId),
                    );
            }
    }
}

export function shouldRender(
    answers: Answer[],
    question: EvalQuestion,
    hiddenAnswers?: Answer[],
    isWorkflowModule?: boolean,
) {
    if (
        !isWorkflowModule &&
        answers &&
        (answers.length === 0 ||
            answers.filter((answer) => answer.questionId === question.id)
                .length === 0)
    ) {
        return false;
    }
    const hiddenAnswerQuestionIds =
        hiddenAnswers?.map(({ questionId }) => questionId) ?? [];

    if (hiddenAnswerQuestionIds.includes(question.id)) {
        return false;
    }
    if (!question.dependencies) {
        return true;
    }
    if (!question.dependencies.conditions) {
        return true;
    }
    if (!question.dependencies.conditions.length) {
        return true;
    }
    if (!answers) {
        return false;
    }
    return question.dependencies.conditions.every((d) =>
        isConditionMet(d, answers, question.evaluationModuleId),
    );
}

const WFRQuestionAnswerList: React.FC<WFRQuestionAnswerListProps> = observer(
    ({ isNoteEditable = true, ...props }) => {
        const store = useStore(WorkflowReviewStore);
        const questionsWithDependencies = props.module?.questions.filter(
            (r) => r.dependencies?.conditions.length,
        );

        const licensedModule = store.licensedModules.find(
            (module) => module.id === props.module?.id,
        );

        useEffect(() => {
            // Set up unanswered dependent questions with empty answers
            const questions = props.module?.questions;
            const evalModuleId = props.module?.evaluationModuleId;

            for (const question of questions ?? []) {
                // Get all dependent questions for the current question
                const dependentQuestions = questions?.filter((quest) =>
                    quest.dependencies?.conditions.find((cond) => {
                        return (
                            (cond as QuestionDependencyQuestionAnswerCondition)
                                .dependsOnQuestionId === question.id
                        );
                    }),
                );

                for (const depQuestion of dependentQuestions ?? []) {
                    // Check if the dependent question already has an answer
                    const dependentQuestionAnswer = store.getAnswerForQuestion(
                        depQuestion,
                        evalModuleId,
                    );

                    // If the dependent question does not have an answer, set an empty answer
                    if (!dependentQuestionAnswer) {
                        store.setAnswerForQuestion(
                            depQuestion,
                            [],
                            dependentQuestionAnswer,
                            evalModuleId,
                            store.enableAutoBindClips,
                        );
                    }
                }
            }
        }, [props.module?.questions, props.module?.evaluationModuleId, store]);

        const triggerQuestions = (props.module?.sortedQuestion ?? []).filter(
            (q) => {
                var evalQuestion = q as EvalQuestion;
                evalQuestion.evaluationModuleId = props.module
                    ?.evaluationModuleId as string;
                const hidden =
                    store.workflowReviewerInfo?.workflowType ===
                        WorkflowConditionType.APT && props.hideHiddenAnswers
                        ? store.workflowReviewerInfo?.workflowInfo.hiddenAnswers
                        : store.workflowReviewerInfo?.workflowType ===
                          WorkflowConditionType.EvaluationDispute
                        ? store.workflowReviewerInfo?.workflowInfo.answers.filter(
                              (answer) =>
                                  store.workflowReviewerInfo?.workflowType ===
                                      WorkflowConditionType.EvaluationDispute &&
                                  !store.workflowReviewerInfo.workflowInfo?.answerDisputes.some(
                                      (dispute) =>
                                          dispute.answerId === answer.id,
                                  ),
                          )
                        : [];
                const answersForModule = store.answers.filter(
                    (answer) =>
                        answer.evaluationModuleId ===
                        (props.module?.evaluationModuleId as string),
                );
                return shouldRender(
                    answersForModule,
                    evalQuestion,
                    hidden,
                    licensedModule?.isWorkflowModule && props.isWorkflowModule,
                );
            },
        );
        const getQuestionsWithDependencies = (questions: Question[]) => {
            const answersQuestionsMap =
                questions.map((question) => ({
                    questionId: question.id,
                    value: store.answers
                        .find((answer) => answer.questionId === question.id)
                        ?.answerTags.map(
                            (answerTag) => answerTag.tag?.value,
                        )[0],
                })) ?? [];

            const childDeps =
                questionsWithDependencies?.filter((q) => {
                    return q.dependencies?.conditions.some((condition) => {
                        return !!answersQuestionsMap.find(
                            ({ questionId, value }) =>
                                condition.type ===
                                    QuestionDependencyType.QuestionAnswer &&
                                condition.dependsOnQuestionId === questionId &&
                                condition.value === value,
                        );
                    });
                }) ?? [];

            const parentDeps = questions
                .flatMap((q) =>
                    q.dependencies?.conditions
                        .map((condition) =>
                            (props.module?.sortedQuestion ?? []).find(
                                (question) =>
                                    condition.type ===
                                        QuestionDependencyType.QuestionAnswer &&
                                    condition.dependsOnQuestionId ===
                                        question.id,
                            ),
                        )
                        .filter(isNotNull),
                )
                .filter(isNotNull);

            return [...questions, ...childDeps, ...parentDeps].sort((a, b) => {
                if (a.order < b.order) {
                    return -1;
                }
                if (a.order > b.order) {
                    return 1;
                }
                return 0;
            });
        };

        const sortedQuestions =
            !props.isWorkflowModule &&
            store.workflowReviewerInfo?.workflowType ===
                WorkflowConditionType.APT
                ? getQuestionsWithDependencies(triggerQuestions)
                : triggerQuestions;

        if (!props.isWorkflowModule) {
            store.visibleQuestionIds = sortedQuestions.map(
                (question) => question.id,
            );
        }

        const moduleUIModel = store.moduleUIModels.get(
            props.module?.evaluationModuleId ?? "",
        );

        return props.module?.questions ? (
            <>
                {sortedQuestions?.map((question) => {
                    const answer = store.getAnswerForQuestion(
                        question,
                        moduleUIModel?.licensedModule.evaluationModuleId,
                    );

                    if (question.isActive) {
                        const leftBorder =
                            question.parentId &&
                            questionsWithDependencies?.includes(question)
                                ? hexToRGB(ThemeColors.purple, 1)
                                : questionsWithDependencies?.includes(question)
                                ? hexToRGB(ThemeColors.red, 0.75)
                                : question.parentId
                                ? hexToRGB(ThemeColors.blue, 0.5)
                                : undefined;

                        if (
                            question.answerType.answerTypeName ===
                                AnswerTypeNames.ScaledResponse ||
                            question.answerType.answerTypeName ===
                                AnswerTypeNames.ScaledBooleanResponse ||
                            question.answerType.answerTypeName ===
                                AnswerTypeNames.QuestionGrouping
                        ) {
                            return (
                                <WFRScaledResponseAnswer
                                    moduleUIModel={moduleUIModel}
                                    answerDisputes={answer?.answerDisputes}
                                    question={question}
                                    standalone={props.standalone}
                                    leftBorderColor={leftBorder}
                                    key={question.id}
                                    isDisabled={!props.isEditable}
                                    isNoteDisabled={!isNoteEditable}
                                    showDisputeCheckbox={
                                        props.disputeHasStarted
                                    }
                                    disableErrorHighlighting
                                />
                            );
                        } else if (
                            question.answerType.answerTypeName ===
                                AnswerTypeNames.TagResponse ||
                            question.answerType.answerTypeName ===
                                AnswerTypeNames.ScoredTagResponse ||
                            question.answerType.answerTypeName ===
                                AnswerTypeNames.TextResponse ||
                            question.answerType.answerTypeName ===
                                AnswerTypeNames.DateResponse
                        ) {
                            return (
                                <WFRTagOrFillInResponseAnswer
                                    key={question.id}
                                    standalone={props.standalone}
                                    leftBorderColor={leftBorder}
                                    moduleUIModel={moduleUIModel}
                                    question={question}
                                    isDisabled={!props.isEditable}
                                    isNoteDisabled={!isNoteEditable}
                                />
                            );
                        } else if (
                            question.answerType.answerTypeName ===
                            AnswerTypeNames.StarRating
                        ) {
                            return (
                                <WFRStarRatingAnswer
                                    key={question.id}
                                    standalone={props.standalone}
                                    moduleUIModel={moduleUIModel}
                                    question={question}
                                    leftBorderColor={leftBorder}
                                    isDisabled={!props.isEditable}
                                    showDisputeCheckbox={
                                        props.disputeHasStarted
                                    }
                                />
                            );
                        } else {
                            return "";
                        }
                    } else {
                        return "";
                    }
                })}
            </>
        ) : null;
    },
);

export default WFRQuestionAnswerList;
