import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import React, { useEffect, useMemo, useRef } from "react";
import Chart from "react-google-charts";
import { GoogleChartWrapper, GoogleViz } from "react-google-charts/dist/types";
import theme from "../../../Theme/AppTheme";
import { defaultColorScheme } from "./GraphColorSchemes";

const chartName = "AcxHorizontalBar";
type AxesScaleTypes = "log" | "mirrorLog" | null;
type GoogleDataFormat = any[][];

interface OwnProps {
    data?: GoogleDataFormat;
    previousData?: GoogleDataFormat;

    colorScheme?: string[];
    dataOpacity?: number;

    legendPosition: "none" | "bottom" | "in" | "right" | "top";
    showGrid?: boolean;
    xTitle?: string;

    onSelect?: (
        series: string | null,
        x: string | number | null,
        y: string | number | null | any,
        chartId?: string,
    ) => void;

    chartAreaLeftOffset?: number | string;
    chartAreaTopOffset?: number | string;
    chartAreaHeight?: number | string;
    chartAreaWidth?: number | string;
    chartHeight?: string;

    annotationTextSize?: number;

    showDemoData?: boolean;
    asWidget?: boolean;
    stacked?: boolean;
    verticalScaleType?: AxesScaleTypes;
    horizontalScaleType?: AxesScaleTypes;
    textPosition?: "out" | "in" | "none" | undefined;

    onChartReady?: (
        chartWrapper: GoogleChartWrapper,
        google: GoogleViz,
    ) => void;

    usesHtmlTooltips?: boolean;
    vAxisTextStyle?: Record<string, unknown>;
    vAxisFontSize?: string;
    hAxisFontSize?: string;
    chartId?: string;
    hAxisFormat?: string;
}

type Props = OwnProps;

const AcxHorizontalBarGraph: React.FC<Props> = (props) => {
    const wrapper = useRef<null | any>();
    useEffect(() => {
        return () => {
            wrapper.current?.getChart()?.clearChart();
        };
    }, []);

    const data = Boolean(props.showDemoData) ? fake_data : props.data;

    const showLegend =
        (data?.[0].filter((d) => !Boolean(d.role)).length ?? 0) > 2;

    const options = useMemo(() => {
        const res = {
            fontName: "Inter",
            dataOpacity: props.dataOpacity ?? 0.65,
            annotations: {
                alwaysOutside: true,
                textStyle: {
                    fontName: "Inter",
                    fontSize: props.annotationTextSize ?? 18,
                    bold: true,
                    italic: true,
                    // The color of the text.
                    color: "black",
                    // The color of the text outline.
                    auraColor: "transparent",
                    // The transparency of the text.
                    opacity: 0.6,
                },
            },
            animation: {
                startup: true,
                duration: 1000,
                easing: "out",
            },
            isStacked: Boolean(props.stacked),
            colors:
                props.colorScheme && props.colorScheme.length
                    ? props.colorScheme
                    : defaultColorScheme,
            tooltip: {
                textStyle: { fontName: "Inter" },
                isHtml: !!props.usesHtmlTooltips,
            },

            // bar: {groupWidth: '75%',},
            legend: {
                position: showLegend ? props.legendPosition : "none",
                alignment: "start",
                textStyle: {
                    fontName: "Inter",
                },
            },
            chart: {},
            chartArea: {
                left:
                    props.chartAreaLeftOffset ?? (props.asWidget ? "9%" : "2%"),
                top: props.chartAreaTopOffset ?? "5%",
                width: props.chartAreaWidth ?? (props.asWidget ? "84%" : "95%"),
                height:
                    props.chartAreaHeight ?? (props.asWidget ? "77%" : "90%"),
            },
            vAxis: {
                textPosition:
                    props.textPosition ?? (props.asWidget ? undefined : "in"),
                scaleType: null as AxesScaleTypes,
                titleTextStyle: {
                    color: theme.palette.gray[400],
                    fontSize: props.asWidget ? 13 : 16,
                    fontName: "Inter Bold",
                },
                textStyle: {
                    color: props.asWidget
                        ? theme.palette.gray[400]
                        : theme.palette.black,
                    auraColor: "none",
                    fontName: "Inter Bold",
                    ...props.vAxisTextStyle,
                    fontSize: props.asWidget ? 8 : props.vAxisFontSize ?? 11,
                },
            },
            hAxis: {
                scaleType: null as AxesScaleTypes,
                title: props.xTitle,
                titleTextStyle: {
                    color: theme.palette.gray[400],
                    fontSize: props.asWidget ? 13 : 16,
                    fontName: "Inter Bold",
                },
                textStyle: {
                    color: theme.palette.gray[400],
                    fontSize: props.asWidget ? 10 : props.hAxisFontSize ?? 13,
                    fontName: "Inter Bold",
                },
                gridlines: {
                    color: props.showGrid ? "E7E7E7" : "none",
                },
                format: props?.hAxisFormat,
            },
        };

        res.vAxis.scaleType = props.verticalScaleType ?? null;
        res.hAxis.scaleType = props.horizontalScaleType ?? null;

        return res;
    }, [
        props.annotationTextSize,
        props.asWidget,
        props.chartAreaHeight,
        props.chartAreaLeftOffset,
        props.chartAreaTopOffset,
        props.chartAreaWidth,
        props.colorScheme,
        props.dataOpacity,
        props.hAxisFontSize,
        props?.hAxisFormat,
        props.horizontalScaleType,
        props.legendPosition,
        props.showGrid,
        props.stacked,
        props.textPosition,
        props.usesHtmlTooltips,
        props.vAxisFontSize,
        props.vAxisTextStyle,
        props.verticalScaleType,
        props.xTitle,
        showLegend,
    ]);

    // chartEvents callbacks are memoized and can only access props from initial render
    // this ref stores an updated data prop for use in the chartEvent select callback
    const dataRef = useRef({ data: props.data });

    useEffect(() => {
        dataRef.current = { data: props.data };
    }, [props.data]);

    return (
        <>
            {Boolean(data) && (
                <Chart
                    width={`100%`}
                    height={
                        props.chartHeight
                            ? props.chartHeight
                            : props.asWidget
                            ? `97%`
                            : "100%"
                    }
                    chartType="BarChart"
                    loader={
                        <AcxLoadingIndicator
                            color="secondary"
                            alternate="PuffLoader"
                            size={96}
                            style={
                                props.chartHeight
                                    ? {
                                          height: props.chartHeight,
                                          zIndex: 1098,
                                      }
                                    : {}
                            }
                        />
                    }
                    data={Boolean(props.previousData) ? undefined : data}
                    diffdata={
                        Boolean(props.previousData)
                            ? {
                                  old: props.previousData,
                                  new: props.data,
                              }
                            : undefined
                    }
                    options={options}
                    chartEvents={[
                        {
                            eventName: "select",
                            callback: ({ chartWrapper }) => {
                                const chart = chartWrapper.getChart();
                                const selection = chart.getSelection();
                                if (selection.length === 1) {
                                    const [selectedItem] = selection;
                                    const dataTable =
                                        chartWrapper.getDataTable();
                                    const { row, column } = selectedItem;

                                    if (row !== null && column !== null) {
                                        const rowLabel =
                                            dataRef.current.data?.[
                                                row + 1
                                            ]?.[0];
                                        console.log(
                                            `${chartName} selection : ${JSON.stringify(
                                                {
                                                    row,
                                                    column,
                                                    series: dataTable?.getColumnLabel(
                                                        column,
                                                    ),
                                                    X: rowLabel,
                                                    Y: dataTable?.getValue(
                                                        row,
                                                        column,
                                                    ),
                                                    rowProps:
                                                        dataTable?.getRowProperties(
                                                            row,
                                                        ),
                                                    columnProps:
                                                        dataTable?.getColumnProperties(
                                                            column,
                                                        ),
                                                },
                                            )}`,
                                        );

                                        props.onSelect?.(
                                            dataTable?.getColumnLabel(column) ??
                                                null,
                                            rowLabel,
                                            dataTable?.getValue(row, column) ??
                                                null,
                                            props.chartId,
                                        );
                                    } else if (row !== null) {
                                        const rowLabel =
                                            dataRef.current.data?.[
                                                row + 1
                                            ]?.[0];
                                        console.log(
                                            `${chartName} selection : ${JSON.stringify(
                                                {
                                                    row,
                                                    column,
                                                    X: rowLabel,
                                                    rowProps:
                                                        dataTable?.getRowProperties(
                                                            row,
                                                        ),
                                                },
                                            )}`,
                                        );

                                        props.onSelect?.(
                                            null,
                                            rowLabel,
                                            null,
                                            props.chartId,
                                        );
                                    } else if (column !== null) {
                                        console.log(
                                            `${chartName} selection : ${JSON.stringify(
                                                {
                                                    row,
                                                    column,
                                                    series: dataTable?.getColumnLabel(
                                                        column,
                                                    ),
                                                    columnProps:
                                                        dataTable?.getColumnProperties(
                                                            column,
                                                        ),
                                                },
                                            )}`,
                                        );

                                        props.onSelect?.(
                                            dataTable?.getColumnLabel(column) ??
                                                null,
                                            null,
                                            null,
                                        );
                                    }
                                }
                            },
                        },
                    ]}
                    getChartWrapper={(w, g) => {
                        wrapper.current = w;
                        props.onChartReady?.(w, g);
                    }}
                />
            )}
        </>
    );
};

const fake_data = [
    [
        "Element",
        "2019",
        {
            type: "string",
            role: "annotation",
        },
        "2020",
        {
            type: "string",
            role: "annotation",
        },
    ],
    ["Brand Value", 8.94, "Brand Value", 10.94, "Brand Value"],
    ["Brand Detractor", 10.49, "Brand Detractor", 23.4, "Brand Detractor"],
    ["Program Value", 19.3, "Program Value", 2.33, "Program Value"],
    [
        "Program Detractor",
        21.45,
        "Program Detractor",
        12.1233,
        "Program Detractor",
    ],
];

export default AcxHorizontalBarGraph;
