import { Grid, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { useMemo, useRef, useState } from "react";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { useStore } from "utils/useStore";
import theme from "Theme/AppTheme";
import AcxVerticalBarGraph from "components/UI/Visualization/AcxVerticalBarGraph";
import AcxLineGraph from "components/UI/Visualization/AcxLineGraph";
import {
    EEOverTimeID,
    ISignalsVizDefinition,
    TrendingTopicsID,
    ConversationsOverTimeId,
} from "../Models/SignalsModels";
import { signalsChartColorScheme } from "components/UI/Visualization/GraphColorSchemes";
import { ChartPlaceholderContainer } from "./ChartPlaceholderContainer";
import NoData from "./NoData";
import SignalsReportStore from "../Store/SignalsReportStore";
import InfoSvg from "SvgIcons/InfoSvg";
import AcxHorizontalBarGraph from "components/UI/Visualization/AcxHorizontalBarGraph";
import SignalsDrilldownStore from "../Store/SignalsDrilldownStore";
import AcxMenu from "components/UI/Menu/AcxMenu";
import MessageIcon from "@mui/icons-material/Message";
import JoinRightIcon from "@mui/icons-material/JoinRight";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";

const chartTimeoutMessage =
    "Report has timed out. Try a shorter date/period range, adjust your filters, or click refresh to try again.";

type Props = ISignalsVizDefinition;

const SignalsChart: React.FC<Props> = observer(
    ({
        id,
        chartType,
        yTitle,
        xTitle,
        data,
        seriesNames,
        visibleSeries,
        isStackedBar,
        dataEndpoint,
        vizOptions,
        selectionType,
        dataGridStore,
    }) => {
        const store = useStore(SignalsReportStore);

        // Ref to store the mouse position
        const mousePosition = useRef<{ x: number | null; y: number | null }>({
            x: 0,
            y: 0,
        });

        // Event handler to update the ref's current value
        const handleMouseMove = (event) => {
            mousePosition.current = { x: event.clientX, y: event.clientY };
        };

        React.useEffect(() => {
            // Attach mousemove listener
            window.addEventListener("mousemove", handleMouseMove);

            // Cleanup listener on component unmount
            return () => {
                window.removeEventListener("mousemove", handleMouseMove);
            };
        }, []);

        const [currentSelection, setCurrentSelection] = useState<{
            x: string | number | null;
            y: any;
            series: any;
            chartId?: string;
        }>({
            x: null,
            y: undefined,
            series: undefined,
            chartId: undefined,
        });

        const signalsDrilldownStore = useStore(SignalsDrilldownStore);
        const isComparePrev = store.selectedComparePrevBools[id];

        const [isOpen, setIsOpen] = useState(false);

        let series: Record<string, unknown>[] | undefined;
        if (visibleSeries && (seriesNames?.length ?? 0) > 0) {
            series = [];
            for (const seriesName of seriesNames ?? []) {
                if (visibleSeries.includes(seriesName)) series.push({});
                else series.push({ lineWidth: 0, visibleInLegend: false });
            }
        }

        const validData = (d) => {
            if (chartType !== "Table") {
                return (d?.flat().length ?? 0) > 1;
            } else {
                return !!dataGridStore.rows.length;
            }
        };

        const isValidData = useMemo(
            () => validData(data),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [data, dataGridStore.rows.length],
        );

        const chartOnSelect = (
            series: string | null,
            x: string | number | null,
            y: any,
            chartId?: string,
        ) => {
            if (selectionType === "Topics") {
                setCurrentSelection({ x, y, series, chartId });
                setIsOpen(true);
            } else {
                signalsDrilldownStore.onSelect(series, x, y, chartId);
            }
        };

        const closeMenu = () => {
            setIsOpen(false);
        };

        const vAxisDefaultTextStyle = {
            bold: false,
            italic: false,
            fontSize: "12",
            color: theme.palette.grey[600],
        };

        const vAxistDefaultTitleStyle = {
            italic: false,
            bold: false,
            fontSize: "12",
            color: "#374151", // Please update once palette is updated | Old Color: theme.palette.grey[800],
        };

        const parsedVizOptions = JSON.parse(vizOptions ?? "{}");

        const renderChart = () => {
            switch (chartType) {
                case "VerticalBar":
                    return (
                        <AcxVerticalBarGraph
                            chartAreaHeight={75}
                            chartAreaWidth={"100%"}
                            showGrid
                            legendPosition="bottom"
                            data={data}
                            yTitle={yTitle}
                            xTitle={xTitle}
                            vAxisFormat="short"
                            fontSize="1.25rem"
                            vAxisFontSize="12"
                            hAxisFontSize="12"
                            fontBold={false}
                            legendAlignment="center"
                            dataOpacity={1}
                            chartHeight="300px"
                            usesHtmlTooltips
                            slantedText={false}
                            colorScheme={
                                isComparePrev
                                    ? [
                                          theme.palette.secondary.main,
                                          theme.palette.primary[800],
                                      ]
                                    : signalsChartColorScheme
                            }
                            vAxisTitleStyle={vAxistDefaultTitleStyle}
                            vAxisTextStyle={vAxisDefaultTextStyle}
                            onChartReady={(chartWrapper) =>
                                store.captureChartRef(chartWrapper, id)
                            }
                            stacked={isStackedBar}
                            onSelect={chartOnSelect}
                            chartId={id}
                            {...parsedVizOptions}
                        />
                    );

                case "Line":
                    return (
                        <AcxLineGraph
                            showGrid
                            chartAreaHeight={75}
                            chartAreaWidth={"100%"}
                            legendPosition={
                                dataEndpoint !== EEOverTimeID &&
                                dataEndpoint !== ConversationsOverTimeId
                                    ? "bottom"
                                    : "none"
                            }
                            legendAlignment="center"
                            data={data}
                            yTitle={yTitle}
                            xTitle={xTitle}
                            vAxisFormat="short"
                            vAxisFontSize="12"
                            hAxisFontSize="12"
                            dataOpacity={1}
                            chartHeight="300px"
                            hideDataLabels
                            hideDataMarkers
                            usesHtmlTooltips
                            colorScheme={signalsChartColorScheme}
                            vAxisTitleStyle={vAxistDefaultTitleStyle}
                            vAxisTextStyle={vAxisDefaultTextStyle}
                            onChartReady={(chartWrapper) =>
                                store.captureChartRef(chartWrapper, id)
                            }
                            onSelect={chartOnSelect}
                            chartId={id}
                            series={series}
                            {...parsedVizOptions}
                        />
                    );

                case "HorizontalBar":
                    return (
                        <AcxHorizontalBarGraph
                            chartAreaHeight={"80%"}
                            chartAreaWidth={"85%"}
                            chartAreaLeftOffset={"15%"}
                            showGrid
                            legendPosition="bottom"
                            data={data}
                            dataOpacity={1}
                            chartHeight="300px"
                            usesHtmlTooltips
                            colorScheme={signalsChartColorScheme}
                            vAxisTextStyle={vAxisDefaultTextStyle}
                            textPosition="out"
                            onChartReady={(chartWrapper) =>
                                store.captureChartRef(chartWrapper, id)
                            }
                            onSelect={chartOnSelect}
                            chartId={id}
                            hAxisFormat="0"
                            {...parsedVizOptions}
                        />
                    );
                case "Table":
                    if (dataEndpoint === TrendingTopicsID) {
                        dataGridStore.onCellClick = (params) => {
                            if (params.field === "topicName") {
                                signalsDrilldownStore.onSelectTopic({
                                    chartId: id,
                                    selectedTopic: params.value as string,
                                    topicNumber: params.row.topicNumber,
                                    themeNumber: params.row.themeNumber,
                                });
                            }
                        };
                    }
                    return (
                        <Grid
                            item
                            container
                            xs={12}
                            style={{
                                height: "585px",
                            }}
                        >
                            <AcxDataGrid dataGridStore={dataGridStore} />
                        </Grid>
                    );
            }
        };

        const currentlyLoadingChart = store.getTaskLoading(id);
        const chartErrorPresent = store.getTaskError(id);

        // redraw charts when presentation mode is toggled to adjust chart width for new screen size
        React.useEffect(() => {
            setTimeout(() => {
                store.chartWrappersByChartId[id]?.draw();
            }, 100); // timeout is to ensure the page has rendered before re-drawing
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [store.isPresentationMode]);

        return (
            <>
                <AcxMenu
                    open={isOpen}
                    menuItems={[
                        {
                            id: "header",
                            label: (
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    style={{ color: "black" }}
                                >
                                    <Grid item xs={12}>
                                        {currentSelection.series}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {currentSelection.x}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            fontSize: "32px",
                                            color: theme.palette.blue.main,
                                        }}
                                    >
                                        {currentSelection.y}
                                    </Grid>
                                </Grid>
                            ),
                            props: { disabled: true, style: { opacity: 1 } },
                        },
                        "Divider",
                        {
                            id: "signals-view-conversation-menu-item",
                            label: "View Conversations",
                            props: {
                                onClick: () => {
                                    closeMenu();
                                    signalsDrilldownStore.onSelect(
                                        currentSelection.series,
                                        currentSelection.x,
                                        currentSelection.y,
                                        currentSelection.chartId,
                                    );
                                },
                            },
                            icon: <MessageIcon />,
                        },
                        {
                            id: "signals-view-topics-menu-item",
                            label: "View Topics",
                            props: {
                                onClick: () => {
                                    closeMenu();
                                    signalsDrilldownStore.onSelect(
                                        currentSelection.series,
                                        currentSelection.x,
                                        currentSelection.y,
                                        currentSelection.chartId,
                                        true,
                                    );
                                },
                            },
                            icon: <JoinRightIcon />,
                        },
                    ]}
                    onMenuClose={() => {
                        closeMenu();
                    }}
                    anchorPosition={
                        mousePosition.current.y && mousePosition.current.x
                            ? {
                                  top: mousePosition.current.y,
                                  left: mousePosition.current.x,
                              }
                            : undefined
                    }
                    anchorReference="anchorPosition"
                />
                {currentlyLoadingChart && (
                    <ChartPlaceholderContainer>
                        <AcxLoadingIndicator
                            alternate="PuffLoader"
                            size={50}
                            style={{ zIndex: 1 }}
                        />
                    </ChartPlaceholderContainer>
                )}

                {chartErrorPresent && (
                    <ChartPlaceholderContainer direction={"column"}>
                        <InfoSvg width="50px" height="50px" />
                        <Typography variant="h6">
                            {chartTimeoutMessage}
                        </Typography>
                    </ChartPlaceholderContainer>
                )}

                {isValidData &&
                    !currentlyLoadingChart &&
                    !chartErrorPresent &&
                    renderChart()}

                {!currentlyLoadingChart &&
                    !chartErrorPresent &&
                    !isValidData && (
                        <NoData
                            chartId={id}
                            selectionType={selectionType}
                            dataEndpoint={dataEndpoint}
                            headerText="No data available"
                        />
                    )}
            </>
        );
    },
);

export default SignalsChart;
