import IColDef from "components/UI/AcxDataGrid/IColDef";
import { toJS } from "mobx";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import { SignalReportDatum } from "models/Reporting/ReportDatum";
import { Grid } from "@mui/material";
import AcxProgress from "components/UI/AcxDataGrid/Formatters/AcxProgress";
import React from "react";
import theme from "Theme/AppTheme";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import TrendingDownRoundedIcon from "@mui/icons-material/TrendingDownRounded";
import TrendingFlatRoundedIcon from "@mui/icons-material/TrendingFlatRounded";
import AcxChip from "components/UI/AcxChip";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import moment from "moment";
import AcxClickableCell from "components/UI/AcxDataGrid/AcxClickableCell";

export function getSignalsColumns(
    type:
        | "Classifier With Eddy"
        | "Question Response"
        | "Classifier Prevalence"
        | "Hipaa"
        | "Module Score"
        | "Question"
        | "Safety Events"
        | "Topics"
        | "Contact Type"
        | "Eddy by Contact Type"
        | "Trending Topics",
): IColDef[] {
    const classifierWithEddyColumns: IColDef[] = [
        {
            headerName: "Classifier",
            field: "name",
            type: "string",
            flex: 1,
            sortable: false,
        },
        {
            headerName: "Category",
            field: "classifierCategory",
            valueGetter: (value, row, column, apiRef) => {
                return row.classifierCategory?.name ?? "";
            },
            flex: 1,
            sortable: false,
        },
        {
            headerName: "Eddy Count",
            field: "count",
            type: "number",
            headerAlign: "center",
            flex: 1,
            align: "center",
            sortable: false,
        },
        {
            headerName: "Total Count",
            field: "totalCount",
            type: "number",
            headerAlign: "center",
            flex: 1,
            align: "center",
            sortable: false,
        },
        {
            headerName: "Eddy %",
            field: "percent",
            type: "number",
            headerAlign: "center",
            flex: 1,
            align: "center",
            sortable: false,
        },
    ];

    const classifierPrevalenceColumns: IColDef[] = [
        {
            headerName: "Classifier",
            field: "name",
            type: "string",
            flex: 1,
        },
        {
            headerName: "Category",
            field: "classifierCategory",
            valueGetter: (value, row, column, apiRef) => {
                return row.classifierCategory?.name ?? "";
            },
            flex: 1,
        },
        {
            headerName: "Count",
            field: "count",
            type: "number",
            headerAlign: "center",
            flex: 1,
            align: "center",
        },
        {
            headerName: "%",
            field: "percent",
            type: "number",
            headerAlign: "center",
            flex: 1,
            align: "center",
        },
    ];

    const hipaaColumns: IColDef[] = [
        {
            field: "hipaaResult",
            headerName: "Model Result",
            type: "string",
            flex: 1,
        },
        {
            headerName: "Count",
            field: "count",
            type: "number",
            headerAlign: "center",
            flex: 1,
            align: "center",
        },
        {
            headerName: "%",
            field: "percent",
            type: "number",
            headerAlign: "center",
            flex: 1,
            align: "center",
        },
    ];

    const questionResponseColumns: IColDef[] = [
        {
            headerName: "Answers",
            field: "value",
            type: "string",
            flex: 1,
        },
        {
            headerName: "Eddy Count",
            field: "count",
            type: "number",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: "Total Count",
            field: "totalCount",
            type: "number",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: "Eddy %",
            field: "percent",
            type: "number",
            headerAlign: "center",
            align: "center",
            flex: 1,
        },
    ];

    const moduleScoreColumns: IColDef[] = [
        {
            headerName: "Modules",
            field: "displayName",
            type: "string",
            flex: 1,
        },
        {
            headerName: "Avg Score",
            field: "avgScore",
            type: "number",
            headerAlign: "center",
            align: "center",
            flex: 1,
        },
    ];

    const safetyEventsColumns: IColDef[] = [
        {
            field: "safetyEventResult",
            headerName: "Model Result",
            type: "string",
            flex: 1,
        },
        {
            headerName: "Count",
            field: "count",
            type: "number",
            headerAlign: "center",
            flex: 1,
            align: "center",
        },
        {
            headerName: "%",
            field: "percent",
            type: "number",
            headerAlign: "center",
            flex: 1,
            align: "center",
        },
    ];

    const questionColumns: IColDef[] = [
        {
            headerName: "Questions",
            field: "questionText",
            type: "string",
            flex: 1,
        },
        {
            headerName: "Avg Score",
            field: "avgScore",
            type: "number",
            headerAlign: "center",
            align: "center",
            flex: 1,
        },
    ];

    const topicsColumns: IColDef[] = [
        {
            headerName: "Theme",
            field: "topicLabel",
            type: "string",
            flex: 1,
            sortable: false,
        },
        {
            headerName: "Volume",
            field: "count",
            type: "number",
            headerAlign: "center",
            align: "center",
            flex: 1,
            sortable: false,
        },
        {
            headerName: "%",
            field: "percent",
            type: "number",
            headerAlign: "center",
            align: "center",
            flex: 1,
            sortable: false,
        },
    ];

    const contactsColumns: IColDef[] = [
        {
            field: "label",
            headerName: "Caller Type",
            type: "string",
            flex: 1,
        },
        {
            field: "count",
            headerName: "Volume",
            type: "number",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "percent",
            headerName: "%",
            type: "number",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];

    const eddyByContactTypeColumns: IColDef[] = [
        {
            field: "label",
            headerName: "Caller Type",
            type: "string",
            flex: 1,
        },
        {
            field: "count",
            headerName: "Eddy Count",
            type: "number",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "totalCount",
            headerName: "Total Count",
            type: "number",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "percent",
            headerName: "Eddy %",
            type: "number",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];

    const trendingCols: IColDef[] = [
        {
            headerName: "Topic",
            field: "topicName",
            type: "string",
            flex: 1,
            sortable: false,
            cellClassName: "clickable-cell",
            renderCell: (params: GridRenderCellParams) => {
                return <AcxClickableCell>{params.value}</AcxClickableCell>;
            },
        },
        {
            headerName: "Theme",
            field: "themeName",
            renderCell: (params) => {
                return (
                    <Grid
                        item
                        container
                        style={{ height: "100%" }}
                        alignItems={"center"}
                    >
                        <AcxChip
                            size="small"
                            color={"gray"}
                            label={params.value?.toString()}
                        />
                    </Grid>
                );
            },
            flex: 1,
            sortable: false,
        },
        {
            headerName: "Change",
            field: "percentChange",
            renderCell: (params: GridRenderCellParams) => {
                let value = params.value.toString();
                let isNew = params.row.isNew as boolean;
                let isToZero = params.row.isToZero as boolean;
                return getTrendingComponent(
                    value,
                    isNew,
                    isToZero,
                    true,
                    false,
                    false,
                );
            },
            flex: 1,
            sortable: false,
        },
        {
            headerName: "Percent",
            field: "percent",
            renderCell: (params) => {
                return (
                    <Grid
                        container
                        item
                        xs={12}
                        wrap="nowrap"
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={1}
                    >
                        <Grid item xs={10}>
                            <AcxProgress
                                key={params.id}
                                percentComplete={params.value?.toString()}
                                backgroundColor={theme.palette.primary.main}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            {params.value}%
                        </Grid>
                    </Grid>
                );
            },
            flex: 1,
            sortable: false,
        },
        {
            headerName: "Volume",
            field: "volume",
            type: "number",
            flex: 1,
            sortable: false,
        },
    ];

    let columns: IColDef[] = [];

    if (type === "Classifier With Eddy") {
        columns = classifierWithEddyColumns;
    } else if (type === "Question Response") {
        columns = questionResponseColumns;
    } else if (type === "Classifier Prevalence") {
        columns = classifierPrevalenceColumns;
    } else if (type === "Hipaa") {
        columns = hipaaColumns;
    } else if (type === "Module Score") {
        columns = moduleScoreColumns;
    } else if (type === "Question") {
        columns = questionColumns;
    } else if (type === "Safety Events") {
        columns = safetyEventsColumns;
    } else if (type === "Topics") {
        columns = topicsColumns;
    } else if (type === "Contact Type") {
        columns = contactsColumns;
    } else if (type === "Eddy by Contact Type") {
        columns = eddyByContactTypeColumns;
    } else if (type === "Trending Topics") {
        columns = trendingCols;
    }
    return columns;
}

export function populateDataGrid(args: {
    responseData: SignalReportDatum[];
    rowField: string;
    dataGridStore: AcxDataGridStore;
    initColCount: number;
    selectedRowIds: string[];
    addPercentSigns?: boolean;
    useTotalConversationsForPercent?: boolean;
    useSEIdentifiedCount?: boolean;
    useGroupingId?: boolean;
    sortByEstimatedPercent?: boolean;
    totalConversations?: number;
}): any[] {
    const dateGroupings = args.responseData.map((datum) => datum.x);

    const uniqueDateGroupings = Array.from(new Set(dateGroupings));
    const newCols: IColDef[] = uniqueDateGroupings.map((group) => {
        return {
            field: group ?? "",
            header: group,
            type: "number",
            flex: 1,
            align: "center",
            headerAlign: "center",
            sortable: false,
        };
    });

    const rowNamesSelected = args.responseData.map((datum) => datum.grouping);

    const rowIdsSelected = args.responseData.map(
        (datum) => datum.grouping_identifier,
    );

    const initCols = args.dataGridStore.columns.slice(0, args.initColCount);

    const rowCopy = [...toJS(args.dataGridStore.rows)];

    if (args.sortByEstimatedPercent) {
        rowCopy.sort((a, b) => {
            if (a.estimatedPercentageOfTotal > b.estimatedPercentageOfTotal) {
                return -1;
            } else {
                return 1;
            }
        });
    }
    args.dataGridStore.rows = rowCopy
        .sort((a, b) => {
            if (args.selectedRowIds.includes(a.id)) {
                return -1;
            } else {
                return 1;
            }
        })
        .map((row) => {
            row["count"] = 0;
            row["percent"] = 0;

            let totalCountByRow = {};

            args.responseData.forEach((datum) => {
                const setRowData = () => {
                    row[datum.x] = datum.y;
                    if (args.addPercentSigns) {
                        row[datum.x] = datum.y.toFixed(1) + "%";
                    }
                    row["count"] += datum.y;
                    if (!totalCountByRow[row[args.rowField]]) {
                        totalCountByRow[row[args.rowField]] =
                            datum.y_totalcount ?? 0;
                    } else {
                        totalCountByRow[row[args.rowField]] +=
                            datum.y_totalcount ?? 0;
                    }
                };
                if (args.useGroupingId) {
                    if (row.id === datum.grouping_identifier) {
                        setRowData();
                    }
                } else {
                    if (row[args.rowField] === datum.grouping) {
                        setRowData();
                    }
                }
            });

            row["totalCount"] = totalCountByRow[row[args.rowField]];

            const numOfColWithData = uniqueDateGroupings.filter(
                (group) => row[group] !== null && row[group] !== undefined,
            ).length;

            if (numOfColWithData) {
                row["avgScore"] =
                    (row["count"] / numOfColWithData).toFixed(1) + "%";
            }

            if (args.useTotalConversationsForPercent) {
                row["percent"] =
                    (
                        (row["count"] / (args.totalConversations ?? 1)) *
                        100
                    ).toFixed(1) + "%";
            } else {
                const percentVal =
                    (row["count"] / totalCountByRow[row[args.rowField]]) * 100;

                row["percent"] = isNaN(percentVal)
                    ? "N/A"
                    : percentVal.toFixed(1) + "%";
            }

            if (args.useSEIdentifiedCount) {
                const identifiedCount = args.responseData
                    .filter((datum) => datum.grouping === "Identified")
                    .reduce((previous, current) => {
                        return previous + (current.y ?? 0);
                    }, 0);

                if (
                    row.safetyEventResult &&
                    (row.safetyEventResult === "Acknowledged" ||
                        row.safetyEventResult === "Not Acknowledged")
                ) {
                    row["percent"] =
                        ((row["count"] / identifiedCount) * 100).toFixed(1) +
                        "%";
                }
            }

            // clear out computed data for unselected rows to avoid showing stale data
            if (args.useGroupingId) {
                if (!rowIdsSelected.includes(row.id)) {
                    row["count"] = null;
                    row["percent"] = null;
                    row["avgScore"] = null;
                }
            } else {
                if (!rowNamesSelected.includes(row[args.rowField])) {
                    row["count"] = null;
                    row["percent"] = null;
                    row["avgScore"] = null;
                }
            }

            return row;
        });

    args.dataGridStore.setColumns([...initCols, ...newCols]);

    return args.dataGridStore.rows;
}

export const getTrendingComponent = (
    inputValue: number | string,
    isNew: boolean,
    isToZero: boolean,
    isNegativeRed: boolean,
    isGrayOnly: boolean,
    showMinutes: boolean,
) => {
    let value = inputValue.toString();
    let chipColor = isGrayOnly ? "gray" : isNegativeRed ? "darkGreen" : "red";
    const trendUpIcon = (
        <TrendingUpRoundedIcon
            style={{
                color: isGrayOnly
                    ? theme.palette.gray.main
                    : isNegativeRed
                    ? theme.palette.success.dark
                    : theme.palette.error[700],
                marginRight: theme.spacing(1),
            }}
        />
    );
    const trendDownIcon = (
        <TrendingDownRoundedIcon
            style={{
                color: isGrayOnly
                    ? theme.palette.gray.main
                    : isNegativeRed
                    ? theme.palette.error[700]
                    : theme.palette.success.dark,
                marginRight: theme.spacing(1),
            }}
        />
    );

    const trendFlatIcon = (
        <TrendingFlatRoundedIcon
            style={{
                color: theme.palette.gray.main,
                marginRight: theme.spacing(1),
            }}
        />
    );

    let icon = trendUpIcon;

    if (value.includes("-")) {
        value = value.slice(1);
        chipColor = isGrayOnly ? "gray" : isNegativeRed ? "red" : "darkGreen";
        icon = trendDownIcon;
    } else if (value === "0") {
        chipColor = "gray";
        icon = trendFlatIcon;
    }

    if (showMinutes) {
        const isNegative = inputValue.toString().includes("-");
        const seconds =
            Number(inputValue.toString().slice(isNegative ? 1 : 0)) / 1000; // remove "-" for negative values before parsing

        const duration = moment.duration(seconds, "seconds");

        const formatted: string[] = [];
        if (duration.hours()) formatted.push(`${duration.hours()}h`);
        if (duration.minutes()) formatted.push(`${duration.minutes()}m`);
        if (duration.seconds()) formatted.push(`${duration.seconds()}s`);

        if (seconds < 1) {
            value = "0";
            icon = trendFlatIcon;
            chipColor = "gray";
        } else {
            value = formatted.join(" ");
        }
    }

    return (
        <Grid item container style={{ height: "100%" }} alignItems={"center"}>
            <AcxChip
                size="small"
                color={chipColor as any}
                label={
                    <Grid
                        item
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        {icon}
                        {value}
                        {showMinutes ? "" : "%"}
                    </Grid>
                }
            />
            {isNew && (
                <AcxChip
                    customTooltip="This is a new topic this period, as reflected by the percent change."
                    style={{
                        marginLeft: theme.spacing(1),
                    }}
                    size="small"
                    color="blue"
                    label="New"
                />
            )}
            {isToZero && (
                <AcxChip
                    customTooltip="This topic has gone to zero during this period, as reflected by the percent change."
                    style={{
                        marginLeft: theme.spacing(1),
                    }}
                    size="small"
                    color="gray"
                    label="Zero"
                />
            )}
        </Grid>
    );
};
