import { observer } from "mobx-react";
import React from "react";
import theme from "Theme/AppTheme";
import ConvoWorkflowStore from "./store/ConvoWorkflowStore";
import { useStore } from "utils/useStore";
import { Grid, Paper, Theme, Tooltip, useMediaQuery } from "@mui/material";
import { WorkflowStatus } from "models/Workflows/WorkflowInstance";
import AcxChip from "components/UI/AcxChip";
import AcxExpansion from "components/UI/AcxExpansion";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import ConvoQuestionAnswerList from "./questionComponents/ConvoQuestionAnswerList";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import makeStyles from "@mui/styles/makeStyles";
import { AcxConfirmationDialogWrapper } from "components/UI/AcxConfirmationDialog";
import { truncate } from "lodash";
import SmallScreenWarning from "./SmallScreenWarning";

export const endDialogTitle = "Confirm End Workflow";

const useStyles = makeStyles((theme: Theme) => ({
    header: { height: "50px" },
}));

const ConvoWorkflowContainer = observer(() => {
    const classes = useStyles();
    const store = useStore(ConvoWorkflowStore);

    const isLargeScreen = useMediaQuery("(min-width: 1440px)");

    const isScreenTooSmall = useMediaQuery("(max-width: 1080px)");

    return (
        <Grid
            item
            xs={3}
            style={{
                paddingLeft: theme.spacing(2),
                marginTop: "-32px",
            }}
        >
            <Paper style={{ position: "sticky", top: "-32px" }}>
                <Grid
                    item
                    xs={12}
                    style={{
                        fontWeight: 600,
                        fontSize: "16px",
                        padding: theme.spacing(1),
                    }}
                >
                    Safety Event
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    style={store.anyTaskLoading ? { minHeight: "120px" } : {}}
                >
                    {store.anyTaskLoading ? (
                        <AcxLoadingIndicator
                            size={42}
                            alternate="PuffLoader"
                            style={{ marginTop: "24px" }}
                        />
                    ) : isScreenTooSmall ? (
                        <SmallScreenWarning />
                    ) : (
                        store.workflowLevelInfos?.map((wfLevelInfo, i) => {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    key={i}
                                    style={{
                                        borderTop: "0.5px solid",
                                        borderColor:
                                            theme.palette.lightgrayBorder.main,
                                    }}
                                >
                                    <AcxExpansion
                                        header={
                                            <Grid
                                                item
                                                container
                                                alignItems={"center"}
                                                wrap="nowrap"
                                            >
                                                <AcxChip
                                                    size="small"
                                                    label={
                                                        <Grid
                                                            item
                                                            container
                                                            justifyContent={
                                                                "center"
                                                            }
                                                            alignItems={
                                                                "center"
                                                            }
                                                            wrap="nowrap"
                                                        >
                                                            <ArrowRightAltIcon />

                                                            <Grid item>
                                                                {"Level " +
                                                                    (wfLevelInfo.level +
                                                                        1)}
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    color="blue"
                                                    style={{
                                                        width: "85px",
                                                        marginRight:
                                                            theme.spacing(1),
                                                        borderRadius: "28px",
                                                    }}
                                                />
                                                <Tooltip
                                                    title={
                                                        wfLevelInfo.levelName
                                                    }
                                                >
                                                    <Grid item>
                                                        {truncate(
                                                            wfLevelInfo.levelName,
                                                            {
                                                                length: isLargeScreen
                                                                    ? 40
                                                                    : 20,
                                                            },
                                                        )}
                                                    </Grid>
                                                </Tooltip>
                                            </Grid>
                                        }
                                        body={
                                            <ConvoQuestionAnswerList
                                                convoModuleId={
                                                    wfLevelInfo
                                                        .conversationModule?.id
                                                }
                                                licensedModuleId={
                                                    wfLevelInfo
                                                        .conversationModule
                                                        ?.licensedModuleId
                                                }
                                                isEditable={
                                                    wfLevelInfo.level ===
                                                        store.currentWorkflowLevel &&
                                                    store.currentWorkflowStatus !==
                                                        WorkflowStatus.Closed
                                                }
                                                workflowLevel={
                                                    wfLevelInfo.level
                                                }
                                                module={store.licensedModules.find(
                                                    (m) =>
                                                        m.id ===
                                                        wfLevelInfo
                                                            .conversationModule
                                                            ?.licensedModuleId,
                                                )}
                                                uiModel={store.uiModels?.find(
                                                    (m) =>
                                                        m.conversationModuleId ===
                                                        wfLevelInfo
                                                            .conversationModule
                                                            ?.id,
                                                )}
                                                answers={store.answers}
                                            />
                                        }
                                        rootStyle={{
                                            padding: theme.spacing(1),
                                            flexWrap: "nowrap",
                                        }}
                                        headerClass={classes.header}
                                        expanded={
                                            store.currentWorkflowLevel ===
                                            wfLevelInfo.level
                                        }
                                    />
                                </Grid>
                            );
                        })
                    )}
                </Grid>
            </Paper>
            <AcxConfirmationDialogWrapper
                dialogStore={store.dialogStore}
                title={store.dialogStore.title}
                content={store.dialogStore.subTitle}
                onConfirm={() => {
                    store.advanceOrCloseClassifierWorkflow(
                        !!store.dialogStore.title?.includes(endDialogTitle),
                    );
                    store.dialogStore.isOpen = false;
                }}
                onCancel={() => (store.dialogStore.isOpen = false)}
            />
        </Grid>
    );
});

export default ConvoWorkflowContainer;
