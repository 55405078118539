import { action, makeObservable, observable, reaction } from "mobx";
import { AuthStore } from "stores/AuthStore";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import copy from "copy-to-clipboard";
import { BaseStore } from "stores/BaseStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";

import {
    AllSignalDataEndpoints,
    type SignalDataEndpoint,
    type AISummaryResponse,
    type SignalsRequest,
} from "../Models/SignalsModels";
import { SignalsService } from "services/SignalsService";
import { SignalReportDatum } from "models/Reporting/ReportDatum";

interface AISummary {
    refreshable: boolean;
    outdated: boolean;
    summary?: string;
    feedback?: FeedbackType;
}

export enum FeedbackType {
    Dislike = 1,
    ItsOkay = 2,
    Like = 3,
}

@AcxStore
class AISummariesStore extends BaseStore {
    private messageStore: MessageStore;
    private signalsService: SignalsService = new SignalsService();
    private timeoutErrorMessage =
        "Spotlight has timed out, click refresh to try again.";

    @observable
    isAISummaryRefreshableByChartId: Partial<Record<string, boolean>> = {};

    @observable
    aiSummariesByChartId: Partial<Record<string, string>> = {};

    @observable
    isAISummaryOutdatedByChartId: Partial<Record<string, boolean>> = {};

    @observable
    summaryRequestIdToChartId: Partial<Record<string, string>> = {};

    // Handles signalr "loading" state
    @observable
    isAISummaryLoadingByChartId: Partial<Record<string, boolean>> = {};

    // boolean to control the first fetch of AI Summaries
    @observable
    isInitialAISummaryFetchedByChartId: Partial<Record<string, boolean>> = {};

    @observable
    feedbackStateByChartId: Partial<Record<string, FeedbackType | undefined>> =
        {};

    @observable
    protectedDataBase64ByChartId: Partial<Record<string, string>> = {};

    constructor(private rootStore: IRootStore) {
        super("AI Summaries Store");
        makeObservable(this);

        this.messageStore = this.rootStore.getStore(MessageStore);
        const authStore = this.rootStore.getStore(AuthStore);

        reaction(
            () => authStore.activeOrgId,
            () => {
                this.isAISummaryOutdatedByChartId = {};
                this.isAISummaryRefreshableByChartId = {};
                this.aiSummariesByChartId = {};
                this.summaryRequestIdToChartId = {};
                this.isInitialAISummaryFetchedByChartId = {};
                this.isAISummaryLoadingByChartId = {};
                this.feedbackStateByChartId = {};
                this.protectedDataBase64ByChartId = {};
            },
        );
    }

    isInitialSummaryFetched(chartId: string) {
        return !!this.isInitialAISummaryFetchedByChartId[chartId];
    }

    @action
    setSummaryLoading(chartId: string, loading: boolean) {
        this.isAISummaryLoadingByChartId[chartId] = loading;
    }

    @action
    handleSummaryResponse(response: AISummaryResponse) {
        const chartId = this.summaryRequestIdToChartId[response.requestId];
        if (response.result.successful) {
            this.updateSummaryByRequestId(
                response.requestId,
                response.result.summary,
            );

            if (chartId)
                this.protectedDataBase64ByChartId[chartId] =
                    response.result.protectedDataBase64;
        } else {
            // this handles the failed summary request in the case a failure happens before the 60 second timeout
            this.updateSummaryByRequestId(
                response.requestId,
                this.timeoutErrorMessage,
            );
        }

        if (chartId) this.setSummaryLoading(chartId, false);
        this.summaryRequestIdToChartId[response.requestId] = undefined;
    }

    @action
    updateSummaryByRequestId(requestId: string, summary: string) {
        const chartId = this.summaryRequestIdToChartId[requestId];
        // If no chart id or the req is not loading, early return.
        // Loading can be false here if we timed it out (60sec no response) and we
        // dont want an old req re-populating any new data.
        if (!chartId || !this.isAISummaryLoadingByChartId[chartId]) return;
        this.aiSummariesByChartId[chartId] = this.transformSummary(summary);
        this.summaryRequestIdToChartId[requestId] = undefined;
        this.isAISummaryLoadingByChartId[chartId] = false;
    }

    @action
    prepareForSignalRRequest(requestId: string, chartId: string) {
        this.summaryRequestIdToChartId[requestId] = chartId;
        this.setSummaryLoading(chartId, true);
        // set to true before request so that any "applies" during loading
        // will not re-request the initial summary
        this.isInitialAISummaryFetchedByChartId[chartId] = true;

        // Timeout signalr reqs after 60 seconds
        setTimeout(() => {
            // If the request still loading, call it timed out
            if (this.isAISummaryLoadingByChartId[chartId]) {
                this.setSummaryLoading(chartId, false);
                this.isAISummaryRefreshableByChartId[chartId] = true;
                this.aiSummariesByChartId[chartId] = this.timeoutErrorMessage;
            }
        }, 60000);
    }

    @action
    transformSummary(summary: string) {
        return summary.replaceAll("\n\n", "\n");
    }

    isSummaryLoading(chartId: string) {
        return (
            this.isAISummaryLoadingByChartId[chartId] ||
            this.getTaskLoading(chartId)
        );
    }

    @action
    async refreshSummary(chartId: string, chartState: SignalsRequest, dataEndpoint: SignalDataEndpoint) {
        this.isAISummaryOutdatedByChartId[chartId] = false;
        this.isAISummaryRefreshableByChartId[chartId] = false;
        this.feedbackStateByChartId[chartId] = undefined;
        // do things to make the refresh
        this.setupAsyncTask(chartId, async () => {
            try {
                const result = await this.signalsService.refreshAISummary(
                    dataEndpoint,
                    chartState,
                );
                if (result.successful) {
                    this.aiSummariesByChartId[chartId] = this.transformSummary(
                        result.summary,
                    );
                    this.protectedDataBase64ByChartId[chartId] =
                        result.protectedDataBase64;
                } else {
                    this.summaryRequestIdToChartId[chartId] = undefined;
                    this.aiSummariesByChartId[chartId] =
                        this.timeoutErrorMessage;
                }
            } catch (error) {
                this.aiSummariesByChartId[chartId] = this.timeoutErrorMessage;
            }
        });
    }

    @action
    async refreshSummaryWithRowData(chartId: string, data: SignalReportDatum[], dataEndpoint: SignalDataEndpoint) {
        this.isAISummaryOutdatedByChartId[chartId] = false;
        this.isAISummaryRefreshableByChartId[chartId] = false;
        this.feedbackStateByChartId[chartId] = undefined;

        this.setupAsyncTask(chartId, async () => {
            try {
                const result = await this.signalsService.refreshAISummaryWithRowData(
                    dataEndpoint,
                    data,
                );
                if (result.successful) {
                    this.aiSummariesByChartId[chartId] = this.transformSummary(
                        result.summary,
                    );
                    this.setSummaryLoading(chartId, false);
                    this.protectedDataBase64ByChartId[chartId] =
                        result.protectedDataBase64;
                } else {
                    this.summaryRequestIdToChartId[chartId] = undefined;
                    this.aiSummariesByChartId[chartId] =
                        this.timeoutErrorMessage;
                }
            } catch (error) {
                this.aiSummariesByChartId[chartId] = this.timeoutErrorMessage;
            }
        });
    }

    /**
     * Posts the provided feedback to the AISummaryFeedback endpoint. `feedbackStateByChartId` is
     * optimistically updated with the provided feedback state, in the event the feedback request
     * fails it is set back to undefined.
     * Loading state can be tracked via the task `Feedback-{chartId}`
     * @param chartId
     * @param feedbackType
     * @param feedback
     * @returns `feedbackType` on success and undefined on error.
     */
    @action
    async sendSummaryFeedback(
        chartId: string,
        feedbackType: FeedbackType,
        feedback: string | null = null,
    ) {
        const protectedDataBase64 = this.protectedDataBase64ByChartId[chartId];
        if (!protectedDataBase64) return undefined;

        this.feedbackStateByChartId[chartId] = feedbackType;

        return this.setupAsyncTask(`Feedback-${chartId}`, async () => {
            try {
                await this.signalsService.aiSummaryFeedback(
                    protectedDataBase64,
                    feedbackType,
                    feedback,
                );
                return feedbackType;
            } catch (error) {
                this.feedbackStateByChartId[chartId] = undefined;
                return undefined;
            }
        });
    }

    @action
    setIsSummaryRefreshable(chartId: string, refreshable: boolean) {
        this.isAISummaryRefreshableByChartId[chartId] = refreshable;
    }

    @action
    setIsSummaryOutdated(chartId: string, outdated: boolean) {
        this.isAISummaryOutdatedByChartId[chartId] = outdated;
    }

    copySummaryToClipboard(chartId: string) {
        const summary = this.aiSummariesByChartId[chartId];
        if (!summary) return;
        copy(summary);
        this.messageStore.logInfo("Copied to clipboard");
    }

    getSummary(chartId: string): AISummary {
        return {
            refreshable: this.isSummaryRefreshable(chartId),
            outdated: this.isSummaryOutdated(chartId),
            summary: this.aiSummariesByChartId[chartId],
            feedback: this.feedbackStateByChartId[chartId],
        };
    }

    isSummaryOutdated(chartId: string) {
        return !!this.isAISummaryOutdatedByChartId[chartId];
    }

    isSummaryRefreshable(chartId: string) {
        return !!this.isAISummaryRefreshableByChartId[chartId];
    }

    @action
    clearTimeoutErrors() {
        // TODO: need to refactor this to work for a more generic ID
        AllSignalDataEndpoints.forEach((id) => {
            if (this.aiSummariesByChartId[id] !== this.timeoutErrorMessage)
                return;

            this.aiSummariesByChartId[id] = "";
        });
    }

    didSummaryTimeout(chartId: string) {
        return this.aiSummariesByChartId[chartId] === this.timeoutErrorMessage;
    }
}

export default AISummariesStore;
