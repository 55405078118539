import {
    Module,
    Question,
    SkinnyModule,
} from "components/Admin/Organizations/types/Module.type";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { useCallback } from "react";
import useList from "shared/hooks/useList";
import { v4 as uuidv4 } from "uuid";
import { useStore } from "utils/useStore";
import ModuleService from "../Module.service";
import {
    QuestionDependency,
    QuestionDependencyCondition,
    QuestionDependencyType,
} from "models/Question";

const useOrganizationModules = (orgId: string) => {
    const messageStore = useStore(MessageStore);

    const getData = useCallback(async () => {
        return ModuleService.getSkinnyModules(orgId);
    }, [orgId]);

    const {
        isLoading,
        data: modules,
        refetch,
    } = useList<SkinnyModule>({
        get: getData,
    });

    const duplicate = async (module: Module) => {
        const id = module.id;
        try {
            const res = await ModuleService.getModule(id);
            const questions = duplicateQuestions(res.questions);

            await ModuleService.createModule({
                organizationId: orgId,
                name: res.name,
                lmType: res.lmType,
                organizationStructureMemberIds:
                    res.organizationStructureMemberIds,
                questions,
            });

            messageStore.logMessage(
                `Successfully duplicated module`,
                "success",
            );
            refetch();
        } catch (err) {
            //@ts-ignore
            messageStore.logError(err.message);
        }
    };

    const duplicateQuestions = (questions: Question[]): Question[] => {
        if (questions === null) {
            return [];
        }

        //any instead of Tag[] because we need to be able to delete the tag id
        const tagMap = new Map<string, any>();
        const questionIdMap = new Map<string, string>();
        questions?.forEach((q) => {
            tagMap.set(q.id as string, q.tags);
            questionIdMap.set(q.id as string, uuidv4());
        });

        const copyQuestions = questions?.map((q) => {
            q.tags = tagMap.get(q.id as string).map((tag) => {
                delete tag["id"];
                return tag;
            });

            //duplicate sub questions
            q.questions = duplicateQuestions(q.questions as Question[]);

            //need to clean up dependencies recursively
            if (q.dependencies !== null) {
                (q.dependencies as QuestionDependency).conditions = (
                    q.dependencies as QuestionDependency
                ).conditions.map((c) => {
                    return assignDependenciesForConditionalGroup(
                        c,
                        questionIdMap,
                    );
                });
            }

            q["id"] = questionIdMap.get(q.id as string);
            const answerTypeId = q.answerType?.id;
            const newQ = {
                ...q,
                answerTypeId,
            };

            return newQ;
        });

        return copyQuestions;
    };

    const assignDependenciesForConditionalGroup = (
        condition: QuestionDependencyCondition,
        questionIdMap: Map<string, string>,
    ): QuestionDependencyCondition => {
        if (condition.type === QuestionDependencyType.QuestionAnswer) {
            condition.dependsOnQuestionId = questionIdMap.get(
                condition.dependsOnQuestionId,
            ) as string;
        } else if (condition.type === QuestionDependencyType.ConditionalGroup) {
            condition.conditions = condition.conditions.map((c) => {
                return assignDependenciesForConditionalGroup(c, questionIdMap);
            });
        }

        return condition;
    };

    return {
        modules,
        isLoading,
        refetch,
        duplicate,
    };
};

export default useOrganizationModules;
