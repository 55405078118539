export const answerTypeOptions = {
    "Scaled Response": {
        tagsLabel: "Selections",
    },
    "Tag Response": {
        tagsLabel: "Tags",
    },
    "Star Rating": {
        tagsLabel: "Star Rating",
    },
    "Scaled Boolean Response": {
        tagsLabel: "Tags",
    },
};

export const renderPlacementOptions = {
    0: { label: "Top", value: 0 },
    1: { label: "Left", value: 1 },
    2: { label: "Right", value: 2 },
    3: { label: "Bottom", value: 3 },
    4: { label: "Middle", value: 4 },
};

export const requiredOptions = {
    true: {
        label: "Yes",
        value: true,
    },
    false: {
        label: "No",
        value: false,
    },
};

export const lmTypeOptions = {
    0: {
        label: "Always",
        value: 0,
    },
    1: {
        label: "Hierarchy",
        value: 1,
    },
    2: {
        label: "Workflow",
        value: 2,
    },
    3: {
        label: "Safety Event",
        value: 3,
    },
    4: {
        label: "Conversation",
        value: 4,
    },
};

export const sentimentOptions = {
    0: {
        label: "Positive",
        value: 0,
    },
    1: {
        label: "Neutral",
        value: 1,
    },
    2: {
        label: "Negative",
        value: 2,
    },
};
