import { observer } from "mobx-react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import React from "react";
import { Grid } from "@mui/material";
import theme from "Theme/AppTheme";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import { useStore } from "utils/useStore";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";
import { AuthStore } from "stores/AuthStore";

export const DataLabelingFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    const valuesStore = useStore(ApplicationFilterValuesStore);
    const authStore = useStore(AuthStore);

    if (!authStore.canUserView("Data Labeling")) return null;

    return (
        <Grid container item direction="column">
            <Grid item>
                <AcxSelectSingle
                    id={"application-filters-is-labeled"}
                    options={[
                        { label: "is labeled", value: true },
                        { label: "is not labeled", value: false },
                    ]}
                    labelField="label"
                    valueField="value"
                    defaultValue={{
                        label: props.store.containsDataLabelsString,
                        value: props.store.containsDataLabels,
                    }}
                    customStyle={{
                        container: (provided, state) => ({
                            ...provided,
                            backgroundColor: theme.palette.lightgray.main,
                            // cant find a better way to adjust this width
                            width: props.store.containsDataLabels
                                ? "125px"
                                : "150px",
                        }),
                    }}
                    onChange={(item) => {
                        props.store.setContainsDataLabels(item.value);
                    }}
                />
            </Grid>
            <Grid
                item
                onClick={() => {
                    valuesStore.loadDataLabelingModules();
                }}
            >
                <AcxSelectMulti
                    id="application-filters-data-labeling"
                    fullWidth
                    isLoading={valuesStore.getTaskLoading(
                        ApplicationFilterValuesStore.Tasks
                            .LOAD_DATA_LABELING_MODULES,
                    )}
                    options={valuesStore.dataLabelingModules ?? []}
                    defaultValue={props.store.dataLabels}
                    onChange={(options) => {
                        props.store.setDataLabels(!!options ? options : []);
                    }}
                    valueField="id"
                    labelField="name"
                    containerHeight="auto"
                    maxContainerHeight="96px"
                    alignControls="flex-start"
                    isClearable={true}
                />
            </Grid>
        </Grid>
    );
});
