import { observer } from "mobx-react";
import React from "react";
import theme from "Theme/AppTheme";
import { useStore } from "utils/useStore";
import { Grid, Paper, useMediaQuery } from "@mui/material";
import ConvoQuestionAnswerList from "./questionComponents/ConvoQuestionAnswerList";
import ConvoDataLabelingStore, {
    loadLicensedModule,
} from "./store/ConvoDataLabelingStore";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import SmallScreenWarning from "./SmallScreenWarning";

export const endDialogTitle = "Confirm End Workflow";

const ConvoDataLabelingContainer = observer(() => {
    const store = useStore(ConvoDataLabelingStore);

    const isScreenTooSmall = useMediaQuery("(max-width: 1080px)");

    return (
        <Grid
            item
            xs={3}
            style={{
                paddingLeft: theme.spacing(2),
                marginTop: "-40px",
            }}
        >
            <Paper
                sx={{
                    position: "sticky",
                    height: "calc(100vh - 64px) !important",
                    overflow: "auto",
                }}
            >
                {store.getTaskLoadingV2(loadLicensedModule) ? (
                    <AcxLoadingIndicator size={75} alternate="PuffLoader" />
                ) : isScreenTooSmall ? (
                    <SmallScreenWarning />
                ) : (
                    <>
                        <Grid
                            item
                            xs={12}
                            style={{
                                fontWeight: 600,
                                fontSize: "16px",
                                padding: theme.spacing(1),
                            }}
                        >
                            {store.selectedLicensedModule?.name}
                        </Grid>
                        <Grid item container xs={12} direction={"column"}>
                            <ConvoQuestionAnswerList
                                convoModuleId={
                                    store.selectedConversationModuleId
                                }
                                licensedModuleId={
                                    store.selectedLicensedModule?.id
                                }
                                isEditable
                                isDataLabeling
                                module={store.selectedLicensedModule}
                                uiModel={store.uiModel}
                                answers={store.answers}
                            />
                        </Grid>
                    </>
                )}
            </Paper>
        </Grid>
    );
});

export default ConvoDataLabelingContainer;
