import { Box } from "@mui/material";
import React from "react";
import { observer } from "mobx-react";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import WordsPhrasesStore, { initWordsPhrasesItems } from "./WordsPhrasesStore";
import AcxButton from "components/UI/AcxButton";

interface IWordsPhrasesConfirmationModal {
    store: WordsPhrasesStore;
}

export const WordsPhrasesConfirmationModal = observer(
    ({ store }: IWordsPhrasesConfirmationModal) => {
        const renderTitle = (): string => {
            let title =
                store.isInputStandard === true
                    ? "Switch to Advanced builder?"
                    : "Switch to Standard builder?";
            return title;
        };

        const renderText = (): string => {
            let text =
                store.isInputStandard === true
                    ? "The advanced editor lets you build more complex search queries, but requires expert knowledge of Full Text Search syntax. If you switch then you cannot return to the standard editor without losing your progress on this search. Proceed?"
                    : "Switching to the standard builder will overwrite all progress in the advanced editor. Proceed?";
            return text;
        };

        const renderButtonText = (): string => {
            let buttonText =
                store.isInputStandard === true
                    ? "Switch to Advanced"
                    : "Switch to Standard";
            return buttonText;
        };

        const onConfirmSwitch = () => {
            store.toggleConfirmationModal();
            setTimeout(store.toggleStandardInput, 200);
            store.isInputStandard !== true &&
                store.setWordsPhrasesItems(initWordsPhrasesItems);
        };

        return (
            <AcxDialog
                title={renderTitle()}
                text={renderText()}
                isOpen={store.isConfirmationModalOpen}
                onClose={store.toggleConfirmationModal}
                contentWidth="400px"
                children={
                    <Box>
                        <AcxButton
                            fullWidth
                            id="word-phrases-confirm-switch-button"
                            color="primary"
                            onClick={onConfirmSwitch}
                        >
                            {renderButtonText()}
                        </AcxButton>
                    </Box>
                }
            />
        );
    },
);
