import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import {
    Checkbox,
    ClickAwayListener,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Paper,
    Popper,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DownChevronSvg from "./BaseSelectComponents/DownChevronSvg";
import AcxButton from "../AcxButton";
import AcxMainTextField from "../AcxMainTextField";
import ClearIcon from "@mui/icons-material/Clear";
import theme from "Theme/AppTheme";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";

const useStyles = makeStyles((theme: Theme) => ({
    checkboxItemContainer: {
        backgroundColor: theme.palette.white.main,
        border: "1px solid #c7c7c7",
        borderRadius: "6px",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: "fit-content",
    },
    checkboxContainer: {
        height: "215px",
        overflowY: "auto",
        backgroundColor: theme.palette.lightgray.main,
        paddingTop: theme.spacing(1),
    },
    input: {
        fontSize: "13px",
        border: "1px solid",
        borderColor: theme.palette.lightgrayBorder.main,
        borderRadius: theme.shape.borderRadius,
        minHeight: "32px",
        height: "32px",
        cursor: "pointer",
        padding: "6px",
    },
    applyBtn: {
        borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(1),
    },
    noMargin: {
        marginRight: 0,
    },
    checkboxInnerPadding: {
        padding: theme.spacing(0.5),
    },
}));

interface Props {
    options: any[];
    id: string;
    onChange?: (selectedIds: string[]) => void;
    onApply?: (selectedIds: string[]) => void;
    maxSelectionCount?: number;
    itemTitle?: string;
    defaultSelectedIds?: string[];
    valueField?: string;
    labelField?: string;
    isLockVariant?: boolean;
}

const AcxCompactSelectMulti = observer((props: Props) => {
    const classes = useStyles(props);
    const [isOpen, setIsOpen] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [selectedIds, setSelectedIds] = useState<string[]>(
        props.defaultSelectedIds ?? [],
    );
    const [searchTerm, setSearchTerm] = useState("");
    const ref: any = useRef(null);

    const valueField = props.valueField ?? "value";
    const labelField = props.labelField ?? "label";

    const handleChange = (val, option) => {
        setHasChanged(true);
        let newSelectedIds: string[] = [];
        if (val.target.checked) {
            newSelectedIds = [...selectedIds, option.id];
            setSelectedIds(newSelectedIds);
        } else {
            newSelectedIds = selectedIds.filter((i) => i !== option.id);
            setSelectedIds(newSelectedIds);
        }

        props.onChange?.(newSelectedIds);
    };

    const selectedCountLabel = `${selectedIds.length} ${
        selectedIds.length === 1
            ? props?.itemTitle ?? ""
            : props?.itemTitle
            ? props?.itemTitle + "s"
            : ""
    } ${props.isLockVariant ? "Locked" : "Selected"}`;

    useEffect(() => {
        setSelectedIds(props.defaultSelectedIds ?? []);
    }, [props.defaultSelectedIds]);

    const handleClickAway = () => {
        setIsOpen((prev) => !prev);
        if (hasChanged) {
            props.onApply?.(selectedIds);
        }
        setHasChanged(false);
    };

    const handleApply = () => {
        props.onApply?.(selectedIds);
        setIsOpen(false);
        setHasChanged(false);
    };

    const handleClear = () => {
        setSelectedIds([]);
        setHasChanged(true);
    };

    const renderCheckboxItem = (option) => {
        const isSelected = selectedIds.includes(option[valueField] ?? "");
        const maxSelected = props.maxSelectionCount
            ? selectedIds.length >= props.maxSelectionCount
            : false;
        return (
            <Grid
                container
                item
                xs={12}
                className={classes.checkboxItemContainer}
                key={option[valueField]}
                margin={theme.spacing(1)}
                marginTop={0}
            >
                <Tooltip
                    title={
                        maxSelected && !isSelected
                            ? `Max selection reached`
                            : ""
                    }
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={(val) => handleChange(val, option)}
                                name={option.label}
                                checked={isSelected}
                                disabled={
                                    props.maxSelectionCount
                                        ? selectedIds.length >=
                                              props.maxSelectionCount &&
                                          !isSelected
                                        : false
                                }
                                className={classes.checkboxInnerPadding}
                                icon={
                                    props.isLockVariant ? (
                                        <LockOpenRoundedIcon />
                                    ) : undefined
                                }
                                checkedIcon={
                                    props.isLockVariant ? (
                                        <LockRoundedIcon />
                                    ) : undefined
                                }
                            />
                        }
                        label={option[labelField]}
                        style={
                            props.isLockVariant
                                ? {
                                      overflowWrap: "anywhere",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "100%",
                                  }
                                : { overflowWrap: "anywhere" }
                        }
                        labelPlacement={props.isLockVariant ? "start" : "end"}
                    />
                </Tooltip>
            </Grid>
        );
    };

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                onClick={() => setIsOpen(true)}
                className={classes.input}
                ref={ref}
                id={props.id}
                wrap="nowrap"
            >
                <Typography noWrap>{selectedCountLabel}</Typography>

                <Grid item>
                    <DownChevronSvg />
                </Grid>
            </Grid>
            {isOpen && (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Popper
                        open={isOpen}
                        anchorEl={ref.current}
                        placement="bottom"
                        style={{ zIndex: theme.zIndex.modal }}
                    >
                        <Paper
                            style={{
                                width: ref.current?.clientWidth,
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                style={{ padding: theme.spacing(1) }}
                            >
                                <AcxMainTextField
                                    id={
                                        props.id +
                                        "-search-compact-select-multi"
                                    }
                                    value={searchTerm}
                                    placeholderText={"Search..."}
                                    onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                    }
                                    endAdornment={
                                        <IconButton
                                            size="small"
                                            onClick={() => setSearchTerm("")}
                                        >
                                            <ClearIcon fontSize="small" />
                                        </IconButton>
                                    }
                                    fullWidth
                                />
                            </Grid>

                            <FormGroup>
                                <Grid
                                    container
                                    className={classes.checkboxContainer}
                                    alignContent="flex-start"
                                >
                                    {props.options
                                        .filter((opt) => {
                                            return searchTerm
                                                ? opt[labelField]
                                                      .toLowerCase()
                                                      .includes(
                                                          searchTerm.toLowerCase(),
                                                      )
                                                : true;
                                        })
                                        .map(renderCheckboxItem)}
                                </Grid>
                            </FormGroup>
                            <Grid
                                container
                                justifyContent="space-around"
                                alignItems="center"
                            >
                                <Grid item margin={theme.spacing(1)}>
                                    <AcxButton
                                        fullWidth
                                        id={props.id + "-clear-button"}
                                        onClick={handleClear}
                                        color="white"
                                    >
                                        Clear
                                    </AcxButton>
                                </Grid>
                                <Grid item margin={theme.spacing(1)}>
                                    <AcxButton
                                        fullWidth
                                        id={props.id + "-apply-button"}
                                        color="primary"
                                        onClick={handleApply}
                                    >
                                        Apply
                                    </AcxButton>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Popper>
                </ClickAwayListener>
            )}
        </>
    );
});

export default AcxCompactSelectMulti;
