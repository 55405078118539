import { Close } from "@mui/icons-material";
import { Grid, IconButton, Menu, Typography } from "@mui/material";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxButton from "components/UI/AcxButton";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { ApplicationFiltersInstanceStore } from "stores/ApplicationFilters/ApplicationFiltersInstanceStore";
import {
    ApplicationFiltersStore,
    SavedFilter,
} from "stores/ApplicationFilters/ApplicationFiltersStore";
import { AsyncTaskStatus } from "stores/BaseStore";
import { useStore } from "utils/useStore";
import { VisibilityToggle } from "./VisibilityToggle";
import { ApplicationFilterDomain } from "services/ApplicationFiltersService";

interface SaveFiltersButtonProps {
    /**
     * If a store is provided, will create a new saved filter
     * with the filters present in that store. Otherwise will
     * save the filters currently present in ApplicationFiltersStore
     */
    store?: ApplicationFiltersInstanceStore;
    variant?: "menu" | "dialog";
    onSave?(filter: SavedFilter): void | Promise<void>;
    showVisibilityToggle?: boolean;
    defaultFilter?: SavedFilter;
}

interface SaveFilterFormProps {
    onSave(
        name: string,
        visibility: ApplicationFilterDomain,
    ): void | Promise<void>;
    onCancel(): void;
    showVisibilityToggle?: boolean;
    defaultFilter?: SavedFilter;
}

function SaveFilterForm({
    onSave,
    onCancel,
    defaultFilter,
    ...props
}: SaveFilterFormProps & React.ComponentPropsWithoutRef<typeof Grid>) {
    const [name, setName] = useState<string>(defaultFilter?.name ?? "");
    const [visibility, setVisibility] = useState<ApplicationFilterDomain>(
        defaultFilter?.domain ?? ApplicationFilterDomain.User,
    );
    const [isSaving, setIsSaving] = useState(false);

    return (
        <Grid container direction="column" rowSpacing={2} {...props}>
            <Grid item>
                <AcxMainTextField
                    id="conversations-filter-save-popper-name-text-input"
                    fullWidth
                    labelText="Filter Name"
                    containerStyle={{
                        width: "100%",
                    }}
                    value={name}
                    onKeyDown={(event) => event.stopPropagation()}
                    onChange={(e) => setName(e.target.value)}
                />
            </Grid>
            {props.showVisibilityToggle && (
                <Grid item>
                    <VisibilityToggle
                        defaultValue={visibility}
                        onChange={setVisibility}
                    />
                </Grid>
            )}
            <Grid
                container
                item
                direction="row"
                columnSpacing={1}
                justifyContent="flex-end"
            >
                <Grid item>
                    <AcxButton
                        onClick={() => onCancel()}
                        sx={(theme) => ({
                            color: theme.palette.gray[700],
                            backgroundColor: theme.palette.white.main,
                            border: `1px solid ${theme.palette.gray[200]}`,
                            ":hover": {
                                color: theme.palette.gray[700],
                                backgroundColor: theme.palette.white.main,
                                border: `1px solid ${theme.palette.gray[200]}`,
                                opacity: "75%",
                            },
                        })}
                    >
                        Cancel
                    </AcxButton>
                </Grid>
                <Grid item>
                    <AcxButton
                        id="application-filters-save"
                        onClick={async () => {
                            if (!name) return;
                            setIsSaving(true);
                            await onSave(name, visibility);
                            setIsSaving(false);
                        }}
                        disabled={!name}
                        loading={isSaving}
                    >
                        Save
                    </AcxButton>
                </Grid>
            </Grid>
        </Grid>
    );
}

const SaveFiltersButton = observer(function ({
    children = "Save",
    store,
    variant,
    showVisibilityToggle,
    defaultFilter,
    onSave,
    ...props
}: React.PropsWithChildren<
    Partial<
        Omit<
            React.ComponentPropsWithoutRef<typeof AcxButton>,
            keyof SaveFiltersButtonProps
        >
    > &
        SaveFiltersButtonProps
>) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);
    const messageStore = useStore(MessageStore);

    const [isOpen, setIsOpen] = useState(false);
    const anchorEl = useRef<HTMLButtonElement>(null);

    async function internalOnSave(
        name: string,
        visibility?: ApplicationFilterDomain,
    ) {
        if (!name || name === "") return;

        const valid = await applicationFiltersStore.validateWordsAndPhrases();
        if (!valid)
            return messageStore.logError(
                "Invalid Words and Phrases Syntax, please correct the following before continuing: \n" +
                    applicationFiltersStore.wordsAndPhrasesStore
                        .wordsPhrasesFtsSyntaxError,
            );

        if (
            applicationFiltersStore.savedFilters.filter(
                (item) => item.name === name,
            ).length
        )
            return messageStore.logError("Filter names must be unique");

        let result: SavedFilter | AsyncTaskStatus;

        if (
            !applicationFiltersStore.selectedSavedFilter &&
            store?.startDate &&
            store.endDate
        ) {
            store?.setDatePair(
                applicationFiltersStore.quickFiltersStore.startDate,
                applicationFiltersStore.quickFiltersStore.endDate,
            );
        }

        if (!store) {
            result = await applicationFiltersStore.saveCurrentFilters(
                name,
                visibility,
            );
        } else {
            result = await applicationFiltersStore.saveFilters(
                store,
                name,
                visibility,
            );
        }

        if (result === AsyncTaskStatus.Error) {
            return messageStore.logError(
                "Failed to save filters. Please try again.",
            );
        }

        messageStore.logInfo(`'${name}' successfully saved.`);

        applicationFiltersStore.setSelectedSavedFilters(result);

        onSave?.(result);
        setIsOpen(false);
    }

    function internalOnCancel() {
        setIsOpen(false);
    }

    return (
        <React.Fragment>
            {variant === "dialog" && (
                <AcxDialog
                    title="Save Filter"
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    contentWidth="400px"
                    dialogContentChildren={
                        <SaveFilterForm
                            showVisibilityToggle={showVisibilityToggle}
                            defaultFilter={defaultFilter}
                            onSave={internalOnSave}
                            onCancel={internalOnCancel}
                        />
                    }
                    hideCloseButton
                    hideCancelButton
                />
            )}
            {variant === "menu" && (
                <Menu
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    anchorEl={anchorEl.current}
                    anchorReference="anchorEl"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: -8,
                        horizontal: "right",
                    }}
                    slotProps={{
                        paper: {
                            sx: (theme) => ({
                                paddingInline: theme.spacing(3),
                                paddingBlock: theme.spacing(2),
                                minWidth: "320px",
                                border: `0.5px solid ${theme.palette.gray[200]}`,
                                borderRadius: theme.spacing(1),
                            }),
                        },
                    }}
                >
                    <Grid container direction="column" rowSpacing={3}>
                        <Grid
                            container
                            item
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                <Typography
                                    sx={(theme) => ({
                                        color: theme.palette.gray[600],
                                        fontSize: "20px",
                                        fontWeight: 600,
                                        lineHeight: "32px",
                                    })}
                                >
                                    Save Filter
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    size="small"
                                    onClick={() => setIsOpen(false)}
                                >
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <SaveFilterForm
                            item
                            showVisibilityToggle={showVisibilityToggle}
                            defaultFilter={defaultFilter}
                            onSave={internalOnSave}
                            onCancel={internalOnCancel}
                        />
                    </Grid>
                </Menu>
            )}
            <AcxButton
                fullWidth
                id="save-filters-save"
                size="small"
                color="primary"
                onClick={() => setIsOpen(true)}
                tooltip="Save filter as template"
                disabled={applicationFiltersStore.wordsAndPhrasesStore.hasError}
                ref={anchorEl}
                {...props}
            >
                {children}
            </AcxButton>
        </React.Fragment>
    );
});

export default SaveFiltersButton;
